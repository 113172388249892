import { Component, ViewChild, inject } from '@angular/core'
import { ActivatedRoute, Router } from '@angular/router'
import { Store } from '@ngrx/store'
import { IconNamesEnum } from 'ngx-bootstrap-icons'
import { BackEndService } from 'src/app/services/backend.service'
import { NewsArticleComponent } from '../news/news-article/news-article.component'
import { NgScrollbarModule } from 'ngx-scrollbar'
import { NgbAlert, NgbModal, NgbProgressbar } from '@ng-bootstrap/ng-bootstrap'

import { PublicPageHeaderComponent } from '../../shared/components/public-page-header/public-page-header.component'
import { CookieService } from 'ngx-cookie-service'
import { AuthService } from 'src/app/services/auth.service'
import { FormsModule } from '@angular/forms'

@Component({
    selector: 'app-public-page',
    // standalone: true,
    // imports: [],
    templateUrl: './public-page.component.html',
    styleUrls: ['./public-page.component.scss'],
    standalone: true,
    imports: [PublicPageHeaderComponent, NgbAlert, NgbProgressbar, NgScrollbarModule, NewsArticleComponent, FormsModule]
})
export class PublicPageComponent {
  @ViewChild('registrationModal') registrationModal: any

  public iconNames = IconNamesEnum

  private cookieService = inject(CookieService)
  public store = inject(Store)
  private route = inject(ActivatedRoute)
  private router = inject(Router)
  private backendService = inject(BackEndService)
  private modalService = inject(NgbModal)
  private authService = inject(AuthService)

  public area : string = ''
  public articleID : number = 0
  public loading = true
  public expired = false


  public userEmail      = ''
  public userPassword   = ''

  public userFullname = ''
  public userTelephone = ''
  public userOrganization = ''
  public trialRequested = true

  public message = ''
  public trialMessage = ''

  public isLoading = false
  public hash: string = ''

  ngOnInit(): void {
    this.hash = this.route.snapshot.queryParamMap.get('id') ?? ''
    if (this.hash.length > 4) {
      this.backendService.validatePublicHash(this.hash).subscribe((response: any) => {
        this.loading = false
        if (response.result) {
          if (response.result.expired) {
            this.expired = true
            this.router.navigate(['/login'])
          } else {
            const override = atob(this.route.snapshot.queryParamMap.get('o') ?? '')

            const registered = this.cookieService.get(this.hash) || override == (response.result.articleID.toString() ?? 'none')
            if (!registered) {
              this.modalService.open(this.registrationModal, {
                backdropClass: 'diffuse-backdrop',
                keyboard: false,
                backdrop: 'static'
              })
            }
            this.area = response.result.area
            this.articleID = response.result.articleID
          }
        }
      })
    }
  }

  onApplyForTrial() {
    this.isLoading = true
    this.authService.requestTrial({
      userEmail: this.userEmail,
      userFullname: this.userFullname,
      userTelephone: this.userTelephone,
      userOrganization: this.userOrganization,
      trialRequested: this.trialRequested.toString(),
      area: this.area,
      articleID: this.articleID.toString()
    }).subscribe((response:any) => {
      this.isLoading = false
      if (response.ok) {
        this.cookieService.set(this.hash, 'true', 1)
        this.modalService.dismissAll()
      } else {
        this.trialMessage = response.message
      }
    })
  }
}