import { Component, OnInit, OnDestroy, ChangeDetectionStrategy, Input, ViewChild, inject, CUSTOM_ELEMENTS_SCHEMA, ChangeDetectorRef, Directive, TemplateRef, ViewChildren, QueryList } from '@angular/core'
import { ParamMap, RouterLink } from '@angular/router'
import { Situation } from '../situations-article.model'
import { BaseArticleComponent } from 'src/app/shared/components/base-article-component/base-article-component'
import { Dictionary, SearchCheckboxOption, SearchDateRangeOption, SearchNumbersOption, SearchOptionGroup, SearchOptionType, SearchStringOption } from 'src/app/shared/components/filtering-search-component-base/filtering-search-types'
import { expandCollapse, openClose, pulseAnimation } from 'src/app/shared/animations/open-close-animations'
import { SavedSearchService } from 'src/app/services/saved-search.service'
import { IconNamesEnum, NgxBootstrapIconsModule } from 'ngx-bootstrap-icons'
import { NgbPopover, NgbNavModule, NgbNav } from '@ng-bootstrap/ng-bootstrap'
import { take } from 'rxjs'
import { InstrumentIDService } from 'src/app/services/instrument-id-service'
import { PhoneFormatPipe } from '../../../shared/pipes/phone-format.pipe'
import { LinkedInComponent } from '../../../shared/components/linked-in/linked-in.component'
import { DocumentsViewerComponent } from '../../../shared/components/documents-viewer/documents-viewer.component'
import { ListItemComponent } from '../../../shared/components/list-item/list-item.component'
import { MasterRouterLinkDirective } from '../../../../../projects/lib-master-detail/src/lib/directives/master-router-link.directive'
import { CourtDocumentsComponent } from '../../../shared/components/court-documents/court-documents'
import { StoreClosingSaleViewComponent } from '../../../shared/components/store-closing-sale-view/store-closing-sale-view.component'
import { AssetSaleViewComponent } from '../../../shared/components/asset-sale-view/asset-sale-view.component'
import { KEIPViewComponent } from '../../../shared/components/keip-view/keip-view.component'
import { KERPViewComponent } from '../../../shared/components/kerp-view/kerp-view.component'
import { PlanViewComponent } from '../../../shared/components/plan-view/plan-view.component'
import { CapitalStructureComponent } from '../../../shared/components/capital-structure/capital-structure.component'
import { RetentionArticleComponent } from '../../data/retentions/retention-article/retention-article.component'
import { NgTemplateOutlet, NgClass, AsyncPipe, CurrencyPipe, DatePipe, DecimalPipe } from '@angular/common'
import { PrePostCapitalStructureComponent } from '../../../shared/components/prepost-capital-structure/prepost-capital-structure.component'
import { HeaderComponent } from '../../../shared/components/detail-header/detail-header.component'
import { NgScrollbar, NgScrollbarModule } from 'ngx-scrollbar'
import { LoadingStatusComponent } from '../../../shared/components/loading-status/loading-status.component'
import { RosterComponent } from 'src/app/shared/components/roster/roster.component'
import { DownloadDialogComponent } from 'src/app/shared/components/download-dialog/download-dialog.component'
import { CalendarEvent } from 'angular-calendar'
import { OrganizationOverviewComponent } from 'src/app/shared/components/organization-overview/organization-overview.component'
import dayjs  from 'dayjs'
import utc from 'dayjs/plugin/utc'
import timezone from 'dayjs/plugin/timezone'
import advanced from 'dayjs/plugin/advancedFormat'
import 'add-to-calendar-button'
import { CalendarEventBodyComponent } from 'src/app/shared/components/calendar-event-body/calendar-event-body.component'
import { transition, trigger, useAnimation } from '@angular/animations'
import { situationFollowOptions } from 'src/app/shared/follow-options'
import { FollowableLinkComponent } from 'src/app/shared/components/followable-link/followable-link.component'

declare let window: any

@Directive({
    selector: 'ng-template[type]',
    standalone: true
})
export class SectionTemplate {
    template = inject<TemplateRef<SectionTemplate>>(TemplateRef)

    @Input() type!: string
    public situation!: Situation
}

@Component({
    animations: [
        openClose,
        expandCollapse,
        trigger('showEvent', [
            transition(
                '* => *',
                useAnimation(pulseAnimation, {
                    params: {
                        timings: '300ms',
                        scale: 1.05
                    }
                })
            )
        ])
    ],
    schemas: [CUSTOM_ELEMENTS_SCHEMA],
    selector: 'app-situations-article',
    templateUrl: './situations-article.component.html',
    styleUrls: ['./situations-article.component.scss'],
    changeDetection: ChangeDetectionStrategy.OnPush,
    providers: [
        InstrumentIDService,
    ],
    standalone: true,
    imports: [
        LoadingStatusComponent,
        NgTemplateOutlet,
        NgScrollbarModule,
        HeaderComponent,
        NgbNavModule,
        RouterLink,
        PrePostCapitalStructureComponent,
        NgxBootstrapIconsModule,
        NgTemplateOutlet,
        RetentionArticleComponent,
        CapitalStructureComponent,
        NgClass,
        PlanViewComponent,
        AssetSaleViewComponent,
        KEIPViewComponent,
        KERPViewComponent,
        StoreClosingSaleViewComponent,
        CourtDocumentsComponent,
        MasterRouterLinkDirective,
        ListItemComponent,
        DocumentsViewerComponent,
        LinkedInComponent,
        NgbPopover,
        AsyncPipe,
        CurrencyPipe,
        DatePipe,
        DecimalPipe,
        PhoneFormatPipe,
        RosterComponent,
        DownloadDialogComponent,
        OrganizationOverviewComponent,
        CalendarEventBodyComponent,
        SectionTemplate,
        FollowableLinkComponent
    ],
})
export class SituationComponent extends BaseArticleComponent<Situation> implements OnInit, OnDestroy {
    @Input() override sections: string[] = [
        'overview',
        'dates',
        'roster',
        'retentions',
        'capitalStructures',
        'plans',
        'assetSales',
        'topCreditors',
        'holdings',
        'news',
    ]
    @Input() expandAll = false
    @Input() printMode = false
    @ViewChild('body') scrollable: NgScrollbar = {} as NgScrollbar
    @ViewChildren(SectionTemplate) templates!: QueryList<SectionTemplate>
    @ViewChild('nav') ngbNav: NgbNav | null = null

    public _window = window

    public instrumentUIUD = null
    public isExpanded: Dictionary<boolean> = {}
    public expandIDFromHolding = ''

    public caseDocumentSearchOptions: SearchOptionGroup[] = [
        {
            groupName: "Document",
            visible: true,
            colour: '#136481',
            options: [
                {
                    icon: IconNamesEnum.Calendar4Range,
                    id: 'content',
                    type: SearchOptionType.stringinput,
                    name: 'Content',
                    lastResort: true,
                    searchString: '',
                    matchingOption: 'phrase',
                    matchingOptionTypes: {
                        exactPhrase: true,
                        allWords: true,
                        someWords: true,
                        booleanMode: true,
                    },
                    checkboxes: [
                        { name: "Description", value: 'description', checked: true },
                        { name: "Content", value: 'content', checked: true },
                    ]
                } as SearchStringOption,
                {
                    icon: IconNamesEnum.Calendar4Range,
                    id: 'docketid',
                    type: SearchOptionType.searchnumbers,
                    name: '#',
                    numberRange: '',
                    lastResort: true,
                    active: false,
                } as SearchNumbersOption,
                {
                    icon: IconNamesEnum.Calendar4Range,
                    id: 'date',
                    type: SearchOptionType.daterange,
                    name: 'Date',
                    minDate: new Date(),
                    maxDate: new Date(),
                } as SearchDateRangeOption,
                /* */
                {
                    icon: IconNamesEnum.Buildings,
                    id: 'documenttypes',
                    name: 'Document Types',
                    type: SearchOptionType.checkboxes,
                    checkboxes: [
                        // {name: "Company Logo", value: '1'},
                        { name: "363 Sale/Asset Purchase Agreement", value: '3' },
                        { name: "Adversary Proceedings", value: '23' },
                        { name: "Affidavit/Declaration", value: '9' },
                        { name: "Bankruptcy Petition", value: '4' },
                        { name: "Claims Register", value: '33' },
                        { name: "Committees", value: '17' },
                        { name: "Confirmations/Emergences", value: '18' },
                        // {name: "Company Announcement", value: '31'},
                        { name: "Conversions", value: '20' },
                        { name: "Court Audio File", value: '35' },
                        { name: "Dismissals", value: '19' },
                        { name: "Daily Filings", value: '2' },
                        { name: "DIP Financing Documents", value: '5' },
                        { name: "Disclosure Statements", value: '6' },
                        // {name: "Examiner Reports", value: '22'},
                        // {name: "Exclusivity Extensions", value: '7'},
                        { name: "Exit Financing", value: '8' },
                        { name: "KEIP and KERP Motions", value: '10' },
                        { name: "Feature Report", value: '25' },
                        { name: "Final Fees", value: '26' },
                        { name: "Hearing Notice/Agenda", value: '42' },
                        // {name: "Form 10-K", value: '27'},
                        // {name: "Form 8-K", value: '28'},
                        // {name: "Form 20-F", value: '29'},
                        // {name: "Form 6-k", value: '30'},
                        // {name: "Form 10-Q", value: '37'},
                        // {name: "Form 424", value: '38'},
                        { name: "Mailing", value: '40' },
                        { name: "Monthly Operating Report (MOR)", value: '11' },
                        { name: "Notice of Appearance/Pro Hac Vice", value: '39' },
                        { name: "Objection", value: '12' },
                        { name: "Other", value: '13' },
                        { name: "Plans", value: '14' },
                        { name: "Plan Summary", value: '24' },
                        { name: "Retentions", value: '16' },
                        { name: "Rule 2019", value: '34' },
                        { name: "Rule 2019 Economic Interest Table", value: '36' },
                        { name: "Stipulations", value: '21' },
                        { name: "Schedules", value: '32' },
                        { name: "Service", value: '41' },
                    ]
                } as SearchCheckboxOption
                /* */
            ]
        },
    ]

    public showAll: Dictionary<boolean> = {}

    public followOptions = structuredClone(situationFollowOptions)

    public documentInitialSearch = {}

    public routerState: any

    public rosterFilterText = ''

    private iidService = inject(InstrumentIDService)
    protected override savedSearchService = inject(SavedSearchService)
    private changeDetector = inject(ChangeDetectorRef)

    public localTimeZone
    public localTimeZoneShort

    public throbber: string | number = 0

    constructor() {
        super()
        this.area = 'situations'
        this.iidService.listener = true
        this.iidService.iidj$.subscribe(iid => {
            this.gotoInstrumentByID(iid)
        })
        this.routerState = history.state

        if (history.state) {
            this.documentInitialSearch = history.state
        }
        dayjs.extend(utc)
        dayjs.extend(timezone)
        dayjs.extend(advanced)

        this.localTimeZone = dayjs.tz.guess()
        this.localTimeZoneShort = dayjs().tz(this.localTimeZone).format('z')
    }

    override ngOnInit(): void {
        super.ngOnInit()
        this.article$.pipe(take(2)).subscribe((a: any) => {
            if (a?.id) {
                if (this.routerState?.['iid']?.length > 0) {
                    this.iidService.setIID(this.routerState?.['iid'])
                }

                this.route.fragment.pipe(take(1)).subscribe(fragment => {
                    if (fragment) {
                        const ngbNvNumber = parseInt(fragment)
                        if (ngbNvNumber > 0) {
                            setTimeout(() => {
                                this.ngbNav?.select(ngbNvNumber)
                            }, 1000)
                        }
                    }
                })
            }
        })
    }
    findTemplate(section: string) : TemplateRef<any> | null {
        const sectionTemplate = this.templates.find( t => t.type == section)
        if (sectionTemplate) {
            return sectionTemplate.template
        } else {
            return null
        }
    }

    getSectioName(section: string) : string {
        switch (section) {
            case 'overview':
                return 'Overview'
            case 'dates':
                return 'Dates'
            case 'roster':
                return 'Roster'
            case 'retentions':
                return 'retentions'
            case 'capitalStructures':
                return 'Capital Structure'
            case 'plans':
                return 'Plans'
            case 'assetSales':
                return 'Asset Sales'
            case 'keipkerp':
                return 'KEIP/KERP'
            case 'topCreditors':
                return 'Creditors '
            case 'holdings':
                return 'Holdings'
        }
        return ''
    }

    situationHasCapitalStructure(body: any): boolean {
        let result = false

        if (body?.['capital_structure'] && typeof body['capital_structure'] === 'object') {
            for (const structure of Object.values(body['capital_structure'])) {
                if ((structure as any[]).length > 0) {
                    result = true
                    break
                }
            }
        }

        return result && this.sections.includes('capitalStructures')
    }
    situationHasPlans(situation: Situation): boolean {
        return situation && situation.body?.['restructuring_plans']?.length > 0 && this.sections.includes('plans')
    }

    situationHasRoster(situation: Situation): boolean {
        return situation && (
            situation.body?.['roster']?.['Groups']?.length > 0 ||
            situation.body?.['roster']?.['Parties']?.length > 0 ||
            situation.body?.['roster']?.['PartiesWithAdvisors']?.length > 0 ||
            situation.body?.['roster']?.['Debtor']?.['Advisor']?.length > 0
        ) && this.sections.includes('roster')
    }
    situationHasRepresentatives(situation: Situation): boolean {
        return situation && (
            situation.body?.['json']?.['representatives']?.length > 0 || situation.body?.['json']?.['representives_combined']?.length > 0
        ) && this.sections.includes('roster')
    }

    situationHasRetentions(situation: Situation): boolean {
        return situation && situation.body?.['retentions']?.length > 0 && this.sections.includes('retentions')
    }

    sitationHasKEIPKERPs(situation: Situation): boolean {
        return situation && (situation.body?.['keip_kerps']?.keip?.length > 0 || situation.body?.['keip_kerps']?.kerp?.length > 0)
    }

    situationHasHoldings(situation: Situation): boolean {
        return situation && situation.body?.['json_holdings']?.length > 0 && this.sections.includes('holdings')
    }

    situationHasTopCreditors(situation: Situation): boolean {
        return situation && (situation.body?.['json_topcreditors']?.topCreditors?.length > 0 || situation.body?.['json_topcreditors']?.securedCreditors?.length > 0) && this.sections.includes('topCreditors')
    }

    situationHasVerifiedTopCreditors(situation: Situation): boolean {
        return situation && situation.body?.['json_topcreditors']?.topCreditors?.length > 0 && situation.body['json_topcreditors'].topCreditors[0].entity_id != 9999999
    }

    situationHasAssetSales(situation: Situation): boolean {
        return situation && situation.body?.['assetsales'] && Object.values(situation.body['assetsales']).reduce((acc: number, part) => acc + (part as any[]).length, 0) > 0 && this.sections.includes('assetSales')
    }

    getColourMode() {
        return document.documentElement.getAttribute('data-bs-theme')
    }

    spanAllAdvisors(party: any): number {
        let result = 1
        if (party.Advisor?.length) {
            result = party.Advisor.reduce((acc: number, advisor: any) => {
                if (advisor.employees.length > 1) {
                    acc = acc + advisor.employees.length - 1
                }
                return acc + 1
            }, 1)
        }
        return result
    }

    createTableWithRowSpans(structure: any[], pathParts: string[], index: number): [number, any[]] {
        if (pathParts.length === index) {
            return [structure.length, structure.map((s) => [s])]
        } else {
            let result: any[] = []
            let rowspan = 1
            const label = pathParts[index]
            for (const node of structure) {
                let [childRowSpan, rows] = this.createTableWithRowSpans(node[label!] ?? [], pathParts, index + 1)


                let td = structuredClone(node)
                delete td[label!]
                if (childRowSpan > 1) {
                    let first = rows[0]
                    result.push([{
                        ...td,
                        rowSpan: childRowSpan
                    }, ...first])
                    result.push(...rows.slice(1))
                    rowspan = childRowSpan
                } else {
                    result.push([{
                        ...td,
                        rowSpan: 1
                    }, ...rows[0] ?? []])
                }
            }
            return [rowspan, result]
        }
    }

    holdingOpenClose(holdingIndex: number, key: string): boolean {
        let decision = this.isExpanded[key]
        if (holdingIndex === 0) {
            decision = !decision
        }
        return decision
    }

    gotoInstrument(event: Event, instrumentID: string) {
        event.preventDefault()
        this.gotoInstrumentByID(instrumentID)
    }

    gotoInstrumentByID(instrumentID: string) {
        this.activeTab = 4
        this.expandIDFromHolding = instrumentID
        setTimeout(() => {
            const element = document.getElementById('instrument-' + instrumentID) as HTMLElement
            element?.scrollIntoView({ behavior: 'smooth' })
        }, 0)
    }

    gotoPlanByID(plan: any) {
        this.activeTab = 5
        this.isExpanded[plan.name] = true
        setTimeout(() => {
            const element = document.getElementById(plan.id) as HTMLElement
            element?.scrollIntoView({ behavior: 'smooth' })
        }, 0)
    }

    gotoHoldings() {
        this.activeTab = 8
    }

    removeCurrency(value: string): string {
        return value.replace(/\D/g, '')
    }

    override getRouteParams(map: ParamMap): number {
        const id = super.getRouteParams(map)
        this.isExpanded = {}
        return id
    }

    getThreeEventsClosestToToday(events: any[]): any[] {
        const today = dayjs().format('YYYY-MM-DD')
        let result: CalendarEvent[] = []
        for (let i = events.length - 1; i >= 0; i--) {
            if (dayjs(events[i].publicationDate).format('YYYY-MM-DD') >= today) {
                result.unshift(events[i])
            } else {
                break
            }
        }
        if (result.length === 0) {
            if (events.length <= 3) {
                result = events
            } else {
                result = events.slice(-3)
            }
        }

        if (result.length > 3) {
            result = events.slice(0, 3)
        }
        if (result.length < 3) {
            result = Array.from({ ...result, length: 3 }).map((e: any) => {
                if (!e) {
                    e = { id: Math.random() * 1000000 }
                }
                return e
            })
        }

        return result
    }

    protected scrollToElement(id: string | number) {
        if (id && document.querySelector('#event-' + id)) {
            this.scrollable?.scrollToElement('#event-' + id!, {
                top: -40,
            })
            this.throbber = id
            this.changeDetector.detectChanges()
        }
    }


    showEventDetail(event: CalendarEvent) {
        this.activeTab = 10
        setTimeout(
            () => this.scrollToElement(event.id!),
            500
        )
    }

    override sendAnalyticsForTabChange(tabID: string) {
        const tabIDint = parseInt(tabID)
        if (isNaN(tabIDint)) {
            this.mixpanel.trackPageView({
                tabID,
                tab: 'tab'
            })
        } else {
            const section = this.sections[parseInt(tabID) - 1]
            if (section) {
                this.mixpanel.trackPageView({
                    tabID,
                    tab: section
                })
            } else {
                switch (tabIDint) {
                    case 99:
                        this.mixpanel.trackPageView({
                            tabID,
                            tab: 'documents'
                        })
                        break
                    case 1000:
                        this.mixpanel.trackPageView({
                            tabID,
                            tab: 'calcbench:situation:Income Statement'
                        })
                        break
                    case 1001:
                        this.mixpanel.trackPageView({
                            tabID,
                            tab: 'calcbench:situation:Balance Sheet'
                        })
                        break
                    case 1002:
                        this.mixpanel.trackPageView({
                            tabID,
                            tab: 'calcbench:situation:Cash Flow'
                        })
                        break
                    case 1003:
                        this.mixpanel.trackPageView({
                            tabID,
                            tab: 'calcbench:situation:SEC Disclosure Footnotes'
                        })
                        break
                    default:
                        this.mixpanel.trackPageView({
                            tabID,
                            tab: 'tab'
                        })

                }
            }
        }
    }

    addHours(time: Date, hours: number): Date {
        return dayjs(time).add(hours, 'hour').toDate()
    }

    reloadEvent(purchased: boolean, entryId: number) {
        if (purchased) {
            this.store.dispatch({ type: '[Articles] Get An Article', args: { area: 'situations', id: this.articleID } })
        }
    }
    hearingDescription(hearingType: string, hearingDetails: string): string {
        let result = ''
        if (hearingType?.length > 0) {
            result = `<p>${hearingType}:&nbsp;</p>`
        }
        return result + hearingDetails
    }
}