import { Component, Input } from '@angular/core'
import { DatePipe } from '@angular/common'
import { CourtDocumentsComponent } from '../court-documents/court-documents'
import { RouterLink } from '@angular/router'

@Component({
    selector: 'store-closing-sale-view',
    templateUrl: './store-closing-sale-view.component.html',
    styleUrls: ['./store-closing-sale-view.component.scss'],
    standalone: true,
    imports: [RouterLink, CourtDocumentsComponent, DatePipe]
})
export class StoreClosingSaleViewComponent {

  @Input() storeclosingsale : any

}
