import { ChangeDetectionStrategy, ChangeDetectorRef, Component, EventEmitter, Input, OnInit, Output, inject } from '@angular/core'
import { AutoFilterTableComponent } from '../auto-filter-table/auto-filter-table.component'
import { Dictionary } from '../filtering-search-component-base/filtering-search-types'
import { IconNamesEnum, NgxBootstrapIconsModule } from 'ngx-bootstrap-icons'
import { FormsModule } from '@angular/forms'
import { expandCollapse, openClose } from '../../animations/open-close-animations'
import { NgTemplateOutlet } from '@angular/common'
import { NgbPopover } from '@ng-bootstrap/ng-bootstrap'
import { LinkedInComponent } from '../linked-in/linked-in.component'
import { PhoneFormatPipe } from '../../pipes/phone-format.pipe'
import { RouterLink } from '@angular/router'
import { Subject, debounceTime } from 'rxjs'
import { DownloadDialogComponent } from '../download-dialog/download-dialog.component'

@Component({
  animations: [
    openClose,
    expandCollapse
  ],
  selector: 'app-roster',
  standalone: true,
  changeDetection: ChangeDetectionStrategy.OnPush,
  imports: [
    AutoFilterTableComponent,
    FormsModule,
    LinkedInComponent,
    NgxBootstrapIconsModule,
    NgbPopover,
    NgTemplateOutlet,
    PhoneFormatPipe,
    RouterLink,
    DownloadDialogComponent
  ],
  templateUrl: './roster.component.html',
  styleUrl: './roster.component.scss'
})

export class RosterComponent implements OnInit {
  @Input() set roster(data: any) {
    this._roster = structuredClone(data)
    this.resetRosterData()
  }
  @Input() expandAll = false
  @Input() articleID = 0

  @Output() activeTab = new EventEmitter<number>()

  _roster: any


  public rosterFilterText = ''
  public isExpanded: Dictionary<boolean> = {}
  public iconNames = IconNamesEnum
  public showAll: Dictionary<boolean> = {}

  public downloadAction = {}

  private searchText$ = new Subject<string>()

  private changeDetector = inject(ChangeDetectorRef)

  ngOnInit() {
    this.searchText$.pipe(debounceTime(750)).subscribe( text => this.filterTables(text))
  }

  resetRosterData() {
    this._roster['Groups'].forEach((group: any) => {
      group.members.forEach((member: any) => {
        member.visible = true
        member['Advisor']?.forEach((advisor: any) => {
          advisor.employees?.forEach((employee: any) => {
            employee.visible = true
          })
          advisor.expanded = false
          advisor.visible = true
        })
        member['Contact']?.forEach((contact: any) => {
          contact.visible = true
        })

      })
      group['Advisor']?.forEach((advisor: any) => {
        advisor.employees?.forEach((employee: any) => {
          employee.visible = true
        })
        advisor.expanded = false
        advisor.visible = true
      })
      group.expanded = false
      group.visible = true
    })

    this._roster['Debtor']['Advisor']?.forEach((advisor: any) => {
      advisor.employees?.forEach((employee: any) => {
        employee.visible = true
      })
      advisor.expanded = false
      advisor.visible = true
    })

    this._roster['Parties'].forEach((party: any) => {
      party.expanded = false
      party.visible = true
      party.Advisor?.forEach((advisor: any) => {
        advisor.employees?.forEach((employee: any) => {
          employee.visible = true
        })
        advisor.expanded = false
        advisor.visible = true

      })
    })
  }

  resetFilterText() {
    this.rosterFilterText = ''
    this.resetRosterData()
    this.changeDetector.detectChanges()
  }

  filterTableData() {
    this.rosterFilterText = this.rosterFilterText.trim()
    this.searchText$.next(this.rosterFilterText.toLowerCase())
  }


  filterTables(filterText: string) {
    this.resetRosterData()
    if (filterText === '') {
      this.resetFilterText()
      return
    }
    this._roster['Groups'].forEach((group: any) => {
      group.expanded = false
      for (let member of group.members) {
        if (member.entity_name.toLowerCase().includes(filterText)) {
          member.visible = true
        } else {
          member.visible = false
          member.expanded = false
          if (member?.Advisor) {
            for (let advisor of member?.Advisor) {
              if (advisor.entity_name.toLowerCase().includes(filterText)) {
                advisor.visible = true
              } else {
                advisor.visible = false
                advisor.expanded = false
                for (let employee of advisor?.employees) {
                  employee.visible = employee.entity_name.toLowerCase().includes(filterText)
                  advisor.expanded = advisor.expanded || employee.visible
                }
                advisor.visible = advisor.expanded
              }
              member.expanded = group.expanded || advisor.visible
            }
          }
          if (member?.Contact) {
            for (let contact of member?.Contact) {
              if (contact.entity_name.toLowerCase().includes(filterText)) {
                contact.visible = true
              } else {
                contact.visible = false
              }
              member.expanded = group.expanded || contact.visible
            }
          }
          member.visible = member.expanded
        }
        group.expanded = group.expanded || member.visible
      }


      for (let advisor of group.Advisor) {
        if (advisor.entity_name.toLowerCase().includes(filterText)) {
          advisor.visible = true
        } else {
          advisor.visible = false
          advisor.expanded = false
          for (let employee of advisor?.employees) {
            employee.visible = employee.entity_name.toLowerCase().includes(filterText)
            advisor.expanded = advisor.expanded || employee.visible
          }
          advisor.visible = advisor.expanded
        }
        group.expanded = group.expanded || advisor.visible
      }
      group.visible = group.expanded
    })

    this._roster['Debtor']['Advisor']?.forEach((advisor: any) => {
      if (advisor.entity_name.toLowerCase().includes(filterText)) {
        advisor.visible = true
      } else {
        advisor.visible = false
        advisor.expanded = false
        for (let employee of advisor?.employees) {
          employee.visible = employee.entity_name.toLowerCase().includes(filterText)
          advisor.expanded = advisor.expanded || employee.visible
        }
        advisor.visible = advisor.expanded
      }
    })
    this._roster['Parties'].forEach((party: any) => {
      if (party.entity_name.toLowerCase().includes(filterText)) {
        party.visible = true
      } else {
        party.visible = false
        party['Advisor']?.forEach((advisor: any) => {
          if (advisor.entity_name.toLowerCase().includes(filterText)) {
            advisor.visible = true
          } else {
            advisor.visible = false
            advisor.expanded = false
            if (Array.isArray(advisor.employees)) {
              for (let employee of advisor.employees) {
                employee.visible = employee.entity_name.toLowerCase().includes(filterText)
                advisor.expanded = advisor.expanded || employee.visible
              }
            }
            advisor.visible = advisor.expanded
            party.visible = party.visible || advisor.visible
          }
        })
      }
    })
    this.changeDetector.detectChanges()
  }

  gotoHoldings() {
    this.activeTab.emit(8)
  }

  startRosterDownload() {
    this.downloadAction = {
      export: 'xls',
      title: 'Roster',
      area: 'situations/download/roster',
      parameters: {
        articleID:
        this.articleID
      },
      columns: [],
      order: [],
  }
  }

  getLinkedInHandle(str: string): string {
    try {
      const url = new URL(str)
      return url.pathname.replace('/in/', '').replace('/', '')
    } catch (e) {
      return ''
    }
  }
}