<div class="container-fluid">
    <div class="row">
        <div class="col input-with-clear">
            <input type="text" class="form-control" placeholder="Type to filter" [(ngModel)]="rosterFilterText" (keyup)="filterTableData()">
            @if (rosterFilterText.length) {
            <button type="button" class="btn-close" (click)="resetFilterText()"></button>
            }
        </div>
    </div>
    <div class="row">
        @if (_roster['Debtor']['Advisor']; as advisors) {
        <div class="col">
            <h3 class="column-header">
                DEBTOR ADVISORS
            </h3>
            <ng-container *ngTemplateOutlet="entityWithEmployeesTable; context: {entities: advisors, prefix: 'advisors', colspan: 2}"></ng-container>
        </div>
        }
    </div>
    <div class="row">
        @if (_roster['Representatives']; as representatives) {
        <div class="col">
            <h3 class="column-header">
                Representatives ({{representatives.length}})
            </h3>
        </div>
        }
    </div>
    @if (_roster['Groups']; as groups) {
    <div class="row">
        @if (groups.length) {
        <div class="col">
            <h3 class="column-header">
                GROUPS
            </h3>
            @for (group of groups; track group; let groupIndex = $index) {
            @if (group.visible) {
            <div>
                <h4 (click)="group.expanded = ! group.expanded">
                    <i-bs [name]="iconNames.ChevronDown" [@openClose]="group.expanded ? 'open' : 'closed'" class="d-inline-block"></i-bs>
                    {{ group.entity_name }}
                </h4>
                @if (expandAll || group.expanded) {
                <div @expandCollapse>
                    <div class="collapsible d-flex">
                        <div class="row w-100">
                            @if (group.members.length) {
                            <div class="col-md-6">
                                <h5>
                                    Members ({{group.members.length}})
                                </h5>
                                <table class="table entity-table w-100">
                                    <thead>
                                        <th colspan="2">Company</th>
                                        <th>Role</th>
                                        <th>Start Date</th>
                                        <th>End Date</th>
                                    </thead>
                                    <tbody>
                                        @for (tr of group.members; track tr; let i = $index) {

                                            @if (tr.visible) {

                                            <tr>
                                                @if (tr.Advisor?.length + tr.Contact?.length) {
                                                <td (click)="tr.expanded = ! tr.expanded" class="expanding" style="width: 25px;">
                                                    <i-bs [name]="iconNames.ChevronDown" [@openClose]="tr.expanded ? 'open' : 'closed'" class="d-inline-block"></i-bs>
                                                </td>
                                                } @else {
                                                <td></td>
                                                }
                                                <td>
                                                    <a [routerLink]="tr.url" target="_blank">{{ tr.entity_name }}</a>
                                                </td>
                                                <td>
                                                    @if (tr.role?.length) {
                                                    {{tr.role}}
                                                    } @else {
                                                    {{tr.type}} / {{tr.subtype}}
                                                    }
                                                </td>
                                                <td> {{tr.start_date}}</td>
                                                <td> {{tr.end_date}}</td>
                                            </tr>
                                            @if (tr.Advisor?.length + tr.Contact?.length && tr.expanded) {
                                            <tr @expandCollapse>
                                                <td colspan="100">
                                                    <div class="collapsible">
                                                        @if (tr.Advisor?.length) {
                                                        <ng-container *ngTemplateOutlet="entityWithEmployeesTable; context: {entities: tr.Advisor, prefix: 'gma', colspan: 2}"></ng-container>
                                                        }
                                                        @if (tr.Contact?.length) {
                                                        <ng-container *ngTemplateOutlet="showEmployees; context: {employees: tr.Contact}"></ng-container>
                                                        }
                                                    </div>
                                                </td>
                                            </tr>
                                            }
                                            }

                                            }

                                    </tbody>
                                </table>

                            </div>
                            }
                            @if (group.Advisor.length) {
                            <div class="col-md-6">
                                <h5>Advisors</h5>
                                <ng-container *ngTemplateOutlet="entityWithEmployeesTable; context: {entities: group.Advisor, prefix: 'ga'+ groupIndex, colspan: 2}"></ng-container>
                            </div>
                            }
                            @if (group.holdings.length) {

                            <button class="btn btn-outline-dark mx-2" (click)="gotoHoldings()">Holdings</button>

                            }
                        </div>
                    </div>
                </div>
                }
            </div>
            }
            }
        </div>
        }
    </div>
    }
    <div class="row">
        @if (_roster['Parties']; as parties) {
        @if (parties.length > 0) {
        <div class="col my-2">
            <h3 class="column-header">
                PARTIES
            </h3>
            <table class="table entity-table w-100">
                <thead>
                    <th>Organization</th>
                    <th>Type</th>
                    <th>Advisors</th>
                </thead>
                <tbody>
                    @for (tr of parties; track tr; let i = $index) {

                    @if (rosterFilterText.length || showAll['parties'] || i <= 10) { @if (tr.visible) { <tr>
                        <td [innerHTML]="tr.entity_name_linked"></td>
                        <td [innerHTML]="tr.type_subtype"></td>
                        <td>
                            <ng-container *ngTemplateOutlet="showAdvisorsWithEmployees; context: {value: tr.Advisor}"></ng-container>
                        </td>
                        </tr>
                        }
                        }
                        }
                        @if (rosterFilterText.length === 0 && parties.length > 10) {
                        <tr>
                            <td colspan="10" class="py-2">
                                <button type="button" class="btn btn-outline-dark btn-sm p-1" (click)="showAll['parties'] = !showAll['parties']">
                                    @if (!showAll['parties']) {

                                    Show All ({{parties.length}})

                                    }
                                    @if (showAll['parties']) {

                                    Hide

                                    }
                                </button>
                            </td>
                        </tr>
                        }
                </tbody>
            </table>

        </div>
        }
        }
    </div>
</div>

<ng-template #showEmployees let-employees="employees">
    <div class="row row-cols-1 row-cols-md-2 row-cols-lg-2 g-2 ms-2  w-100">
        @for (contact of employees; track contact) {
        @if (contact.visible) {
        <div class="col">
            <div class="card h-100 mx-1">
                <div class="card-body">
                    <p class="card-text">
                        <ng-container *ngTemplateOutlet="displayContact; context: {contact}"></ng-container>
                    </p>
                </div>
            </div>
        </div>
        }
        }
    </div>
</ng-template>

<ng-template #showAdvisorsWithEmployees let-advisors="value" let-i="i" let-expanded="expanded">
    @for (advisor of advisors; track advisor) {

    @if (advisor.visible) {
    <p (click)="advisor.expanded = ! advisor.expanded">
        <i-bs [name]="iconNames.ChevronDown" [@openClose]="advisor.expanded ? 'open' : 'closed'" class="d-inline-block"></i-bs>
        {{advisor.entity_name}} ({{advisor.role}} @if (advisor.subtype!='Employee') { — }{{advisor.subtype}})
    </p>
    @if (expandAll || expanded || advisor.expanded) {
    <div @expandCollapse>
        <div class="collapsible">
            <div class="row row-cols-1 g-2 ms-2 w-100">
                @for (contact of advisor.employees; track contact) {
                @if (contact.visible) {
                <div class="col">
                    <div class="card h-100 mx-1">
                        <div class="card-body">
                            <p class="card-text">
                                <ng-container *ngTemplateOutlet="displayContact; context: {contact}"></ng-container>
                            </p>
                        </div>
                    </div>
                </div>
                }
                }
            </div>
        </div>
    </div>
    }
    }
    }
</ng-template>

<ng-template #displayContact let-contact="contact">
    <ul class="list-unstyled">
        <li><a target="_blank" href="{{contact.url}}">{{ contact.entity_name }}</a></li>
        @if (contact.telephone) {
        <li><a target="_blank" href="tel:{{contact.telephone}}"><i-bs [name]="iconNames.TelephoneOutbound"></i-bs>&nbsp;{{contact.telephone | phone:'US'}}</a></li>
        }
        @if (contact.email) {
        <li><a target="_blank" href="email:{{contact.email}}"><i-bs [name]="iconNames.EnvelopeAt"></i-bs>&nbsp;{{contact.email}}</a></li>
        }
        @if (contact.web) {
        <li><a target="_blank" [href]="contact.web"><i-bs [name]="iconNames.BrowserChrome"></i-bs>&nbsp;Website</a></li>
        }
        @if (contact.linkedin?.length > 10) {
        <li>
            <a target="_blank" [href]="contact.linkedin" [ngbPopover]="linkedIn" [popoverContext]="{linkedin : getLinkedInHandle(contact.linkedin)}" popoverClass="linkedin-popover" triggers="mouseenter" [autoClose]="'outside'" [openDelay]="200">
                <i-bs [name]="iconNames.Linkedin"></i-bs>&nbsp;LinkedIn
            </a>
        </li>
        }
    </ul>
</ng-template>

<ng-template #linkedIn let-linkedin="linkedin">
    <linked-in [handle]="linkedin"></linked-in>
</ng-template>

<ng-template #entityWithEmployeesTable let-entities="entities" let-prefix="prefix" let-colspan="colspan">
    <table class="table entity-table w-100">
        <thead>
            <th [attr.colspan]="colspan">Company</th>
            <th>Role</th>
            <th>Start Date</th>
            <th>End Date</th>
        </thead>
        <tbody>
            @for (tr of entities; track tr; let i = $index) {

            @if (rosterFilterText.length || showAll[prefix] || i <= 10) { @if (tr.role==='blank' ) { <tr>
                <td colspan="5">
                    <p></p>
                </td>
                </tr>

                } @else {
                @if (tr.visible) {

                <tr>
                    @if (tr.employees?.length) {
                    <td (click)="tr.expanded = ! tr.expanded" class="expanding" style="width: 25px;">
                        <i-bs [name]="iconNames.ChevronDown" [@openClose]="tr.expanded ? 'open' : 'closed'" class="d-inline-block"></i-bs>
                    </td>
                    }
                    @if (tr.employees?.length === 0) {
                    <td></td>
                    }
                    <td>
                        <a [routerLink]="tr.url" target="_blank">{{ tr.entity_name }}</a>
                    </td>
                    <td>
                        @if (tr.role.length) {
                        {{tr.role}}
                        } @else {
                        {{tr.type}} / {{tr.subtype}}
                        }
                    </td>
                    <td> {{tr.start_date}}</td>
                    <td> {{tr.end_date}}</td>
                </tr>
                @if (tr.employees?.length && tr.expanded) {
                <tr @expandCollapse>
                    <td colspan="100">
                        <div class="collapsible">
                            <ng-container *ngTemplateOutlet="showEmployees; context: {employees: tr.employees}"></ng-container>
                        </div>
                    </td>
                </tr>
                }
                }

                }

                }

                }
                @if (rosterFilterText.length === 0 && entities.length > 10) {
                <tr>
                    <td colspan="10" class="py-2">
                        <button type="button" class="btn btn-outline-dark btn-sm p-1" (click)="showAll[prefix] = !showAll[prefix]">
                            @if (!showAll[prefix]) {

                            Show All ({{entities.length}})

                            }
                            @if (showAll[prefix]) {

                            Hide

                            }
                        </button>
                    </td>
                </tr>
                }
        </tbody>
    </table>
</ng-template>
<app-download-dialog [action]="downloadAction"></app-download-dialog>