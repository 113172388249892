@if (storeclosingsale['storeclosingsale_' + storeclosingsale.storeclosingsale_status]; as closingsale) {

<div class="row">
    <div class="col-sm-6 col-md-4 my-2">
        <table class="table">
            <tbody>
                @if (closingsale.sale_commencement_date) {
                <tr>
                    <td><strong>Sale Commencement Date</strong></td>
                    <td>{{closingsale.sale_commencement_date | date }}</td>
                </tr>
                }
                @if (closingsale.sale_termination_date) {
                <tr>
                    <td><strong>Sale Termination Date</strong></td>
                    <td>{{closingsale.sale_termination_date | date }}</td>
                </tr>
                }
            </tbody>
        </table>
    </div>

    @if (closingsale.liquidators_consultants.length) {
    <div class="col-sm-6 col-md-4 my-2">
        <h2>LIQUIDATORS/CONSULTANTS</h2>
        <table class="table">
            <tbody>
                @for (company of closingsale.liquidators_consultants; track company) {
                <tr>
                    <td><a [routerLink]="'/organizations/' + company.entity_id" target="_blank">{{company.entity_name}}</a></td>
                </tr>
                }
            </tbody>
        </table>
    </div>
    }
    @if (closingsale.compensation_for_consultants) {
    <div class="col-sm-6 col-md-4 my-2">
        <h2>COMPENSATION FOR CONSULTANTS</h2>
        <table class="table">
            <thead>
                <tr>
                    <th>Type </th>
                    <th>Fee/Commisssion </th>
                    <th>Net Recovery Percentage </th>
                </tr>
            </thead>
            <tbody>
                @for (comp of closingsale.compensation_for_consultants; track comp) {
                <tr>
                    <td>{{ comp.compensation_type}} {{comp.compensation_type_other}} </td>
                    <td>{{ comp.fee_commission}} </td>
                    <td>{{ comp.net_recovery_min}} {{ comp.net_recovery_max}}</td>
                </tr>
                }
            </tbody>
        </table>
    </div>
    }
</div>
<div class="col-sm-6 col-md-4 my-2">
    <table class="table">
        <tbody>
            @if (closingsale.additional_agent_goods_fee.length) {
            <tr>
                <td><strong>Additional Agent Goods Fee</strong></td>
                <td> {{closingsale.additional_agent_goods_fee }}%</td>
            </tr>
            }
            @if (closingsale.additional_agent_goods_fee_guaranteed.length) {
            <tr>
                <td><strong>Additional Agent Goods Fee Guaranteed Amount</strong></td>
                <td>${{closingsale.additional_agent_goods_fee_guaranteed}}</td>
            </tr>
            }
            @if (closingsale.sale_expense_advance.length) {
            <tr>
                <td><strong>Sale Expense Advance</strong></td>
                <td>${{closingsale.sale_expense_advance}}</td>
            </tr>
            }
            @if (closingsale.sale_expense_budget.length) {
            <tr>
                <td><strong>Sale Expense Budget</strong></td>
                <td>${{closingsale.sale_expense_budget}}</td>
            </tr>
            }
            @if (closingsale.store_bonus_program.length) {
            <tr>
                <td><strong>Store Bonus Program</strong></td>
                <td>${{closingsale.store_bonus_program}}</td>
            </tr>
            }
        </tbody>
    </table>
</div>

@if (closingsale.list_of_stores.length) {
<div class="col-sm-6 col-md-4 my-2">
    <h2>LIST OF STORES</h2>
    <table class="table">
        <thead>
            <tr>
                <th>Store #</th>
                <th>Store Name</th>
                <th>Address 1</th>
                <th>Address 2</th>
                <th>City</th>
                <th>State</th>
                <th>Zip</th>
                <th>Sq Feet</th>
            </tr>
        </thead>
        <tbody>
            @for (store of closingsale.list_of_stores; track store) {
            <tr>

            </tr>
            }
        </tbody>
    </table>
    <table class="table">
        <tbody>
            <tr>
                <td><strong>No. of Stores</strong></td>
                <td> {{closingsale.list_of_stores.length }}%</td>
            </tr>
            @if (closingsale.closingsale.avg_gross_sq_ft.length) {
            <tr>
                <td><strong>Avg. Gross Sq. Ft</strong></td>
                <td> {{closingsale.closingsale.avg_gross_sq_ft }}%</td>
            </tr>
            }
            @if (closingsale.closingsale.avg_net_sq_ft.length) {
            <tr>
                <td><strong>Avg. Net Sq. Ft</strong></td>
                <td> {{closingsale.closingsale.avg_net_sq_ft }}%</td>
            </tr>
            }
        </tbody>
    </table>
</div>
}

}
@if (storeclosingsale.timeline.length) {
<div class="col-sm-6 col-md-4 my-2">
    <h2>TIMELINE</h2>
    <table class="table">
        <tbody>
            @for (entry of storeclosingsale.timeline; track entry) {
            <tr>
                <td><strong>{{entry.type}}</strong></td>
                <td>{{ entry.date_of_timeline | date }}</td>
            </tr>
            }
        </tbody>
    </table>
</div>
}

@if (storeclosingsale.storeclosingsale_notes.length) {
<div class="col-sm-6 col-md-4 my-2">
    <h2>STORE CLOSING NOTES</h2>
    <div [innerHTML]="storeclosingsale.storeclosingsale_notes"></div>
</div>
}

<court-documents [documents]="storeclosingsale.court_documents" colsDefn="col-12"></court-documents>