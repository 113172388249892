import { IconNamesEnum } from 'ngx-bootstrap-icons'
import { BankruptcyCaseGroup, SituationsGroup , DistressedSituationReason, OutOfCourtSituationReason, hideOptionGroupFromTableSelector} from 'src/app/shared/components/filtering-search-component-base/filtering-search-common-definitions'
import { SearchCheckboxOption, SearchDateRangeOption, SearchMultiAutoCompleteOption, SearchNumberRangeOption, SearchOptionGroup, SearchOptionType, SearchStringOption, ShowSearchOptionGroup } from 'src/app/shared/components/filtering-search-component-base/filtering-search-types'

export const situationsSearchOptionGroups: SearchOptionGroup[] = [
    SituationsGroup,
    {
        groupName: "Distressed",
        visible: true,
        colour: '#9BCFB2',
        depends: {
            "situation_type": ['distressed']
        },
        options: [
            {
                ...DistressedSituationReason,
                tableColumn: {
                    visible: false,
                }
            } as SearchCheckboxOption,
        ]
    },
    {
        groupName: "Out-of-Court",
        visible: true,
        colour: '#9BCFB2',
        depends: {
            "situation_type": ['out_of_court_restructuring']
        },
        options: [
            {
                ...OutOfCourtSituationReason,
                tableColumn: {
                    visible: false,
                }
            } as SearchCheckboxOption,
        ]
    },
    {
        ...((cg)=>{
            let situationsGroup = { ...cg }
            situationsGroup.options = Array.from(situationsGroup.options)
            situationsGroup.options.push(
                {
                    icon: IconNamesEnum.List,
                    type: SearchOptionType.checkboxes,
                    id: 'bankruptcy_chapter',
                    name: 'Bankruptcy Chapter',
                    checkboxes: [
                        {
                            name: 'Prepackaged',
                            value: 'Prepackaged',
                            checked: true,
                        },
                        {
                            name: 'Pre-Arranged',
                            value: 'Pre-Arranged',
                            checked: true,
                        },
                        {
                            name: 'Free-Fall',
                            value: 'Free-Fall',
                            checked: true,
                        },
                        {
                            name: 'N/A',
                            value: 'N/A',
                            checked: true,
                        },
                    ]
                }  as SearchCheckboxOption,
                {
                    icon: IconNamesEnum.Check2All,
                    name: 'With Direct Lending Debt',
                    id: 'instrument_lending_method_directlending',
                    type: SearchOptionType.checkboxes,
                    tableColumn: {
                        alwaysHidden: true,
                        visible: false,
                        showInDownloads: false
                    },
                    numberChecked: 0,
                    checkboxes: [
                        {
                            name: 'Yes',
                            value: 'Yes',
                            checked: true,
                        },
                        {
                            name: 'No',
                            value: 'No',
                            checked: true,
                        }
                    ]
                } as SearchCheckboxOption,
                {
                    icon: IconNamesEnum.Check2All,
                    name: 'With Public Traded Debt',
                    id: 'instrument_lending_method_publictradeddebt',
                    type: SearchOptionType.checkboxes,
                    tableColumn: {
                        alwaysHidden: true,
                        visible: false,
                        showInDownloads: false
                    },
                    numberChecked: 0,
                    checkboxes: [
                        {
                            name: 'Yes',
                            value: 'Yes',
                            checked: true,
                        },
                        {
                            name: 'No',
                            value: 'No',
                            checked: true,
                        }
                    ]
                } as SearchCheckboxOption,
                {
                    icon: IconNamesEnum.Check2All,
                    name: 'With Plans',
                    id: 'with_plans',
                    type: SearchOptionType.checkboxes,
                    tableColumn: {
                        alwaysHidden: true,
                        visible: false,
                        showInDownloads: false
                    },
                    numberChecked: 0,
                    checkboxes: [
                        {
                            name: 'Yes',
                            value: 'Yes',
                            checked: true,
                        },
                        {
                            name: 'No',
                            value: 'No',
                            checked: true,
                        }
                    ]
                } as SearchCheckboxOption,
                //@ts-ignore
                {
                    icon: IconNamesEnum.Check2All,
                    type: SearchOptionType.showoptiongroup,
                    name: 'Capital Structures',
                    id: 'capital_structures',
                    tableColumn: {
                        alwaysHidden: true,
                        showInDownloads: false
                    },
                    value: 'trigger'
                } as ShowSearchOptionGroup,

                {
                    icon: IconNamesEnum.Check2All,
                    type: SearchOptionType.showoptiongroup,
                    name: 'Plan Treatments',
                    id: 'plan_treatments',
                    value: 'trigger',
                    tableColumn: {
                        alwaysHidden: true,
                        showInDownloads: false
                    }
                } as ShowSearchOptionGroup,
                {
                    icon: IconNamesEnum.Check2All,
                    type: SearchOptionType.showoptiongroup,
                    name: 'Extra Columns',
                    id: 'extra_columns',
                    tableColumn: {
                        visible: false,
                        alwaysHidden: true,
                        showInDownloads: true,
                    },
                    depends: {
                        hide: ['always']
                    }
                } as ShowSearchOptionGroup,
            )
            return situationsGroup
        })(BankruptcyCaseGroup)
    },
    hideOptionGroupFromTableSelector({
        groupName: 'Assets Sales',
        visible: true,
        colour: '#9BCFB2',
        depends: {
            "situation_type": ['bankruptcy'],
            "assets_sales": [ 'trigger' ]
        },
        options: [
            {
                icon: IconNamesEnum.Calendar4Range,
                id: 'assetsale_going_concern_liquidation',
                name: 'Going Concern/Liquidation Sale',
                type: SearchOptionType.checkboxes,
                tableColumn: {
                    visible: true,
                    runningOrder: 8,
                    showInDownloads: false
                },
                checkboxes: [
                    {
                        name: 'Going Concern Sale',
                    },
                    {
                        name: 'Liquidation Sale',
                    }
                ]
            } as SearchCheckboxOption,
        ]
    }),
    hideOptionGroupFromTableSelector({
        groupName: "Capital Structure",
        visible: true,
        colour: '#9BCFB2',
        depends: {
            "situation_type": ['bankruptcy', 'out_of_court_restructuring'],
            "capital_structures": [ 'trigger' ]

        },
        showInDownloads: false,
        options: [
            {
                icon: IconNamesEnum.At,
                id: 'instrument_top_type',
                name: 'Pre/DIP/Post',
                type: SearchOptionType.checkboxes,
                checkboxes: [
                    {
                        name: 'Pre-petition/transaction',
                        value: 'Pre-petition',
                        checked: true,
                    },
                    {
                        name: 'DIP',
                        value: 'DIP',
                        checked: true,
                        depends: {
                            "situation_type": ['bankruptcy']
                        },
                    },
                    {
                        name: 'Post-petition/transaction',
                        value: 'Post-petition',
                        checked: true,
                    }
                ]
            } as SearchCheckboxOption,
            {
                icon: IconNamesEnum.Tags,
                id: 'instrument_bridge_financing_facility',
                name: 'Bridge Facility',
                type: SearchOptionType.checkboxes,
                checkboxes: [
                    {
                        name: 'Bridge Facility',
                        value: 'Yes',
                        checked: true,
                    },
                ],
                dependsMust: {
                    "instrument_top_type": ['Pre-petition']
                }
            } as SearchCheckboxOption,
            {
                icon: IconNamesEnum.Tags,
                id: 'instrument_new_money_debt',
                name: 'New Money Debt',
                type: SearchOptionType.checkboxes,
                checkboxes: [
                    {
                        name: 'New Money Debt',
                        value: 'Yes',
                        checked: true,
                    }
                ],
                dependsMust: {
                    "instrument_top_type": ['Post-petition']
                }
            } as SearchCheckboxOption,
            {
                icon: IconNamesEnum.CurrencyDollar,
                id: 'instrument_type',
                name: 'Type',
                type: SearchOptionType.checkboxes,
                checkboxes: [
                    {
                        name: 'Bond/Note',
                        value: 'Bond/Note',
                        checked: true,
                    },
                    {
                        name: 'Capital Lease',
                        value: 'Capital Lease',
                        checked: true,
                    },
                    {
                        name: 'Equity',
                        value: 'Equity',
                        checked: true,
                    },
                    {
                        name: 'Intercompany Note',
                        value: 'Intercompany Note',
                        checked: true,
                    },
                    {
                        name: 'Promissory Note',
                        value: 'Promissory Note',
                        checked: true,
                    },
                    {
                        name: 'Loan',
                        value: 'Loan',
                        checked: true,
                    },
                    {
                        name: 'Other',
                        value: 'Other',
                        checked: true,
                    },
                ]
            }  as SearchCheckboxOption,
            {
                icon: IconNamesEnum.CurrencyDollar,
                id: 'instrument_sub_type',
                name: 'Subtype',
                depends: {
                    "instrument_type": ['Bond/Note', 'Capital Lease', 'Equity', 'Intercompany Note', 'Promissory Note', 'Loan', 'Other']
                },
                type: SearchOptionType.checkboxes,
                checkboxes: [
                    {
                        name: 'Debenture',
                        value: 'Debenture',
                        checked: true,
                        depends: {
                            "instrument_type": ['Bond/Note']
                        }
                    },
                    {
                        name: 'Perpetual',
                        value: 'Perpetual',
                        checked: true,
                        depends: {
                            "instrument_type": ['Bond/Note']
                        }
                    },
                    {
                        name: 'Revolving Loan',
                        value: 'Revolving Loan',
                        checked: true,
                        depends: {
                            "instrument_type": ['Loan']
                        },
                    },
                    {
                        name: 'Term Loan',
                        value: 'Term Loan',
                        checked: true,
                        depends: {
                            "instrument_type": ['Loan']
                        },
                    },
                    {
                        name: 'L/C Facility',
                        value: 'L/C Facility',
                        checked: true,
                        depends: {
                            "instrument_type": ['Loan']
                        },
                    },
                    {
                        name: 'CARES Act Loan',
                        value: 'CARES Act Loan',
                        checked: true,
                        depends: {
                            "instrument_type": ['Loan']
                        },
                    },
                    {
                        name: 'Preferred Stock',
                        value: 'Preferred Stock',
                        checked: true,
                        depends: {
                            "instrument_type": ['Equity']
                        },
                    },
                    {
                        name: 'Common Stock',
                        value: 'Common Stock',
                        checked: true,
                        depends: {
                            "instrument_type": ['Equity']
                        },
                    },
                    {
                        name: 'LLC Interests',
                        value: 'LLC Interests',
                        checked: true,
                        depends: {
                            "instrument_type": ['Equity']
                        },
                    },
                    {
                        name: 'Warrants',
                        value: 'Warrants',
                        checked: true,
                        depends: {
                            "instrument_type": ['Equity']
                        },
                    },
                    {
                        name: 'Other',
                        value: 'Other',
                        checked: true,
                        depends: {
                            "instrument_type": ['Other']
                        },
                    },
                ]
            }  as SearchCheckboxOption,

            {
                icon: IconNamesEnum.Lock,
                id: 'instrument_security',
                name: 'Level of Security',
                type: SearchOptionType.checkboxes,
                checkboxes: [
                    {
                        name: 'Secured',
                        value: 'Secured',
                        checked: true,
                    },
                    {
                        name: 'Unsecured',
                        value: 'Unsecured',
                        checked: true,
                    },
                    {
                        name: 'N/A',
                        value: 'N/A',
                        checked: true,
                    },
                ]
            }  as SearchCheckboxOption,
            {
                icon: IconNamesEnum.Lock,
                id: 'instrument_priority',
                name: 'Security Priority',
                type: SearchOptionType.checkboxes,
                checkboxes: [
                    {
                        name: 'Senior',
                        value: 'Senior',
                        checked: true,
                    },
                    {
                        name: 'Junior',
                        value: 'Junior',
                        checked: true,
                    },
                    {
                        name: 'Super Senior',
                        value: 'Super Senior',
                        checked: true,
                    },
                    {
                        name: 'N/A',
                        value: 'N/A',
                        checked: true,
                    },
                ]
            }  as SearchCheckboxOption,
            {
                icon: IconNamesEnum.Lock,
                id: 'instrument_lien_priority',
                name: 'Lien Priority',
                dependsMust: {
                    instrument_top_type: ['Pre-petition', 'Post-petition'],
                    instrument_security:[ 'Secured']
                },
                type: SearchOptionType.checkboxes,
                checkboxes: [
                    {
                        name: 'First Lien',
                        checked: true,
                    },
                    {
                        name: '1.25 Lien',
                        checked: true,
                    },
                    {
                        name: '1.5 Lien',
                        checked: true,
                    },
                    {
                        name: 'Second Lien',
                        checked: true,
                    },
                    {
                        name: '2.5 Lien',
                        checked: true,
                    },
                    {
                        name: 'Third Lien',
                        checked: true,
                    },
                    {
                        name: '3.5 Lien',
                        checked: true,
                    },
                    {
                        name: 'Fourth Lien',
                        checked: true,
                    },
                    {
                        name: 'Other',
                        checked: true,
                    },
                ]
            }  as SearchCheckboxOption,
            {
                icon: IconNamesEnum.Asterisk,
                id: 'instrument_qualities',
                name: 'Qualities',
                type: SearchOptionType.checkboxes,
                checkboxes: [
                    {
                        name: 'Subordinated',
                        value: 'Subordinated',
                        checked: true,
                    },
                    {
                        name: 'OID',
                        value: 'OID',
                        checked: true,
                    },
                    {
                        name: 'PIK',
                        value: 'PIK',
                        checked: true,
                    },
                    {
                        name: 'Unitranche',
                        value: 'Unitranche',
                        checked: true,
                    },
                    {
                        name: 'Convertible',
                        value: 'Convertible',
                        checked: true,
                    },
                ]
            }  as SearchCheckboxOption,
            {
                icon: IconNamesEnum.Asterisk,
                id: 'instrument_involved_parties',
                name: 'Involved Parties',
                type: SearchOptionType.multiautocomplete,
                required: [],
                items: [],
                filterby: 'role',
                searchServiceParameter: {
                    area: 'organizations/autocomplete',
                    parameters: {
                        name: {
                            name: "Organization name",
                            type: "stringinput",
                            format: 'autocomplete',
                            returndata: 'brief',
                        }
                    },
                    window: {
                        start: 1,
                        size: 5,
                    }
                },
                filter: [
                    "Lender",
                    "Admin Agent",
                    "Collateral Agent",
                    "Indenture Trustee",
                    "Underwriter",
                    "Arranger",
                    "Counterparty",
                    "Holder"
                ]
            }  as SearchMultiAutoCompleteOption,
            {
                icon: IconNamesEnum.People,
                id: 'instrument_name',
                type: SearchOptionType.stringinput,
                name: 'Instrument Name',
                tableColumn: {
                    visible: false
                },
                searchString: '',
            } as SearchStringOption,
            {
                icon: IconNamesEnum.Calendar4Range,
                id: 'instrument_dateofissuance',
                type: SearchOptionType.daterange,
                name: 'Issuance Date',
                tableColumn: {
                    visible: false,
                },
                minDate: new Date(),
                maxDate: new Date(),
            } as SearchDateRangeOption,
            {
                icon: IconNamesEnum.Calendar4Range,
                id: 'instrument_maturity_date',
                type: SearchOptionType.daterange,
                name: 'Maturity Date',
                tableColumn: {
                    visible: false,
                },
                minDate: new Date(),
                maxDate: new Date(),
            } as SearchDateRangeOption,
            {
                icon: IconNamesEnum.CurrencyDollar,
                id: 'instrument_amount',
                type: SearchOptionType.numberrange,
                name: 'Issuance Amount',
                minValue: 1,
                maxValue: 100,
                options: {
                    floor: 1,
                    ceil: 100,
                    logScale: true,
                },
                prefix: 'USD',
                suffix: 'M',
            } as SearchNumberRangeOption,
            {
                icon: IconNamesEnum.CurrencyDollar,
                id: 'instrument_outstanding_amount',
                type: SearchOptionType.numberrange,
                name: 'Outstanding Amount',
                minValue: 1,
                maxValue: 100,
                options: {
                    floor: 1,
                    ceil: 100,
                    logScale: true,
                },
                prefix: 'USD',
                suffix: 'M',
            } as SearchNumberRangeOption,
            {
                icon: IconNamesEnum.Asterisk,
                id: 'reason_for_change',
                name: 'Updates',
                type: SearchOptionType.checkboxes,
                checkboxes: [
                    {
                        name: 'Additional Borrowing',
                        value: 'Additional Borrowing',
                        checked: true,
                    },
                    {
                        name: 'Borrowing Base Adjustment',
                        value: 'Borrowing Base Adjustment',
                        checked: true,
                    },
                    {
                        name: 'Converted to Exit Facility',
                        value: 'Converted to Exit Facility',
                        checked: true,
                    },
                    {
                        name: 'Credit Bid',
                        value: 'Credit Bid',
                        checked: true,
                    },
                    {
                        name: 'DIP Roll-Up',
                        value: 'DIP Roll-Up',
                        checked: true,
                    },
                    {
                        name: 'Exchange',
                        value: 'Exchange',
                        checked: true,
                    },
                    {
                        name: 'Initial Issuance',
                        value: 'Initial Issuance',
                        checked: true,
                    },
                    {
                        name: 'Maturity Extension',
                        value: 'Maturity Extension',
                        checked: true,
                    },
                    {
                        name: 'Other',
                        value: 'Other',
                        checked: true,
                    },
                    {
                        name: 'Pay Off',
                        value: 'Pay Off',
                        checked: true,
                    },
                    {
                        name: 'Refinance',
                        value: 'Refinance',
                        checked: true,
                    },
                    {
                        name: 'Reinstatement',
                        value: 'Reinstatement',
                        checked: true,
                    },
                    {
                        name: 'Release Security/Collateral',
                        value: 'Release Security/Collateral',
                        checked: true,
                    },
                    {
                        name: 'Waived',
                        value: 'Waived',
                        checked: true,
                    }
                ]
            }  as SearchCheckboxOption,
            {
                icon: IconNamesEnum.At,
                id: 'instrument_waivers_forbearance',
                name: 'Waiver and Forbearance',
                type: SearchOptionType.checkboxes,
                checkboxes: [
                    {
                        name: 'Waivers',
                        checked: true,
                    },
                    {
                        name: 'Forbearance',
                        checked: true,
                    }
                ]
            } as SearchCheckboxOption,
            {
                icon: IconNamesEnum.List,
                id: 'instrument_fees',
                name: 'Fees',
                type: SearchOptionType.checkboxes,
                checkboxes: [
                    {
                        name: "Admin/Monitoring",
                        value: "Admin/Monitoring Fee",
                    },
                    {
                        name: "Arrangement/Origination",
                        value: "Arrangement/Origination Fee",
                    },
                    {
                        name: "Backstop",
                        value: "Backstop Fee",
                    },
                    {
                        name: "Break-up",
                        value: "Break-up Fee",
                    },
                    {
                        name: "Commitment",
                        value: "Commitment Fee",
                    },
                    {
                        name: "Lender/Agent Professional Fees",
                        value: "DIP Lender/Agent Professional Fees",
                    },
                    {
                        name: "Exit Fee",
                    },
                    {
                        name: "Extension Fee",
                    },
                    {
                        name: "L/C Fee",
                    },
                    {
                        name: "OID",
                    },
                    {
                        name: "Sealed",
                    },
                    {
                        name: "Unused Commitment Fee",
                    },
                    {
                        name: "No Fee",
                        value: "No Fee"
                    },
                    {
                        name: "Other",
                        value: "Other Fee",
                    },
                ],
            },
            {
                icon: IconNamesEnum.Percent,
                id: 'fee_amount_percentage',
                type: SearchOptionType.numberrange,
                name: 'Fee Amount %age',
                depends: {
                    "instrument_fees": []
                },
                tableColumn: {
                    alwaysHidden: true,
                },
                prefix: '',
                suffix: '%',
                minValue: 1,
                maxValue: 25,
                options: {
                    floor: 0,
                    ceil: 25,
                    logScale: false,
                }
            } as SearchNumberRangeOption,
            {
                icon: IconNamesEnum.CurrencyDollar,
                id: 'fee_amount',
                type: SearchOptionType.numberrange,
                name: 'Fee Amount',
                depends: {
                    "instrument_fees": []
                },
                tableColumn: {
                    alwaysHidden: true,
                },
                minValue: 1,
                maxValue: 200000,
                options: {
                    floor: 1,
                    ceil: 200000,
                    logScale: true,
                },
                prefix: 'USD',
                suffix: 'k',
            } as SearchNumberRangeOption,
        ]
    }),
    hideOptionGroupFromTableSelector({
        groupName: "DIP specific",
        showInDownloads: false,
        visible: true,
        colour: '#9BCFB2',
        dependsMust: {
            "instrument_top_type": ['DIP']
        },
        options: [
            {
                icon: IconNamesEnum.Briefcase,
                id: 'dip_status',
                name: 'Status',
                type: SearchOptionType.checkboxes,
                numberChecked: 0,
                checkboxes: [
                    {
                        name: 'Proposed',
                        checked: true,
                    },
                    {
                        name: 'Interim',
                        checked: true,
                    },
                    {
                        name: 'Final',
                        checked: true,
                    }
                ]
            } as SearchCheckboxOption,
            {
                icon: IconNamesEnum.Briefcase,
                id: 'dip_withdrawn',
                name: 'Withdrawn',
                type: SearchOptionType.checkboxes,
                checkboxes: [
                    {
                        name: 'Yes',
                        checked: true,
                    },
                    {
                        name: 'No',
                        checked: true,
                    }
                ]
            } as SearchCheckboxOption,
            {
                icon: IconNamesEnum.At,
                id: 'dip_lender_type',
                name: 'Lender Type',
                type: SearchOptionType.checkboxes,
                dependsMust: {
                    "instrument_top_type": ['DIP']
                },
                checkboxes: [
                    {
                        name: 'Defensive Lender',
                        checked: true,
                    },
                    {
                        name: 'Third Party Lender',
                        checked: true,
                    },
                ]
            } as SearchCheckboxOption,
            {
                icon: IconNamesEnum.At,
                id: 'dip_lender_subtype',
                name: 'Lender Sub-type',
                type: SearchOptionType.checkboxes,
                dependsMust: {
                    "instrument_top_type": ['DIP']
                },
                checkboxes: [
                    {
                        name: 'Pre-petition creditor',
                        checked: true,
                    },
                    {
                        name: 'Affiliate',
                        checked: true,
                    },
                    {
                        name: 'Management',
                        checked: true,
                    },
                    {
                        name: 'True Third Party',
                        checked: true,
                    },
                    {
                        name: 'Stalking Horse Bidder',
                        checked: true,
                    },
                    {
                        name: 'Equity Holder',
                        checked: true,
                    },
                    {
                        name: 'Government',
                        checked: true,
                    },
                ]
            } as SearchCheckboxOption,
            {
                icon: IconNamesEnum.CurrencyDollar,
                id: 'dip_carve_out',
                type: SearchOptionType.numberrange,
                name: 'Carve-out',
                dependsMust: {
                    "instrument_top_type": ['DIP']
                },
                minValue: 1,
                maxValue: 100000000,
                options: {
                    floor: 1,
                    ceil: 100000000,
                    logScale: true,
                },
                prefix: 'USD',
                suffix: '',
            } as SearchNumberRangeOption,
            {
                icon: IconNamesEnum.CurrencyDollar,
                id: 'dip_investigation_budget',
                type: SearchOptionType.numberrange,
                name: 'Investigation Budget',
                dependsMust: {
                    "instrument_top_type": ['DIP']
                },
                minValue: 1,
                maxValue: 1000000,
                options: {
                    floor: 1,
                    ceil: 1000000,
                    logScale: true,
                },
                prefix: 'USD',
                suffix: '',
            } as SearchNumberRangeOption,
            {
                icon: IconNamesEnum.CurrencyDollar,
                id: 'dip_investigation_deadline',
                type: SearchOptionType.numberrange,
                name: 'Investigation Deadline',
                dependsMust: {
                    "instrument_top_type": ['DIP']
                },
                minValue: 1,
                maxValue: 365,
                options: {
                    floor: 1,
                    ceil: 365,
                    logScale: false,
                },
                prefix: '',
                suffix: ' days',
            } as SearchNumberRangeOption,
            {
                icon: IconNamesEnum.CurrencyDollar,
                id: 'dip_ucc_sublimit_amount',
                type: SearchOptionType.numberrange,
                name: 'UCC Sublimit',
                dependsMust: {
                    "instrument_top_type": ['DIP']
                },
                minValue: 1,
                maxValue: 365,
                options: {
                    floor: 1,
                    ceil: 365,
                    logScale: false,
                },
                prefix: '',
                suffix: ' days',
            } as SearchNumberRangeOption,
            {
                icon: IconNamesEnum.At,
                id: 'dip_waivers',
                name: 'Waivers',
                type: SearchOptionType.checkboxes,
                dependsMust: {
                    "instrument_top_type": ['DIP']
                },
                checkboxes: [
                    {
                        name: '506(c)',
                        checked: true,
                    },
                    {
                        name: '552(b)',
                        checked: true,
                    },
                    {
                        name: 'None Found',
                        checked: true,
                    },
                ]
            } as SearchCheckboxOption,
            {
                icon: IconNamesEnum.At,
                id: 'dip_avoidance_action_liens',
                name: 'Avoidance Action Liens',
                type: SearchOptionType.checkboxes,
                dependsMust: {
                    "instrument_top_type": ['DIP']
                },
                checkboxes: [
                    {
                        name: 'Yes',
                        checked: true,
                    },
                    {
                        name: 'No',
                        checked: true,
                    },
                    {
                        name: 'Proceeds only',
                        checked: true,
                    },
                    {
                        name: 'None Found',
                        checked: true,
                    },
                ]
            } as SearchCheckboxOption,
            {
                icon: IconNamesEnum.At,
                id: 'dip_adequate_protections',
                name: 'Adequate Protection',
                type: SearchOptionType.checkboxes,
                dependsMust: {
                    "instrument_top_type": ['DIP']
                },
                checkboxes: [
                    {
                        name: 'Super Priority Administrative Expense Claims',
                        checked: true,
                    },
                    {
                        name: 'Liens',
                        checked: true,
                    },
                    {
                        name: 'Fees & Expenses',
                        checked: true,
                    },
                    {
                        name: 'Interest',
                        checked: true,
                    },
                    {
                        name: 'Financial Information',
                        checked: true,
                    },
                    {
                        name: 'Principal',
                        checked: true,
                    },
                    {
                        name: 'Enforcement Rights',
                        checked: true,
                    },
                    {
                        name: 'Other',
                        checked: true,
                    },
                    {
                        name: 'N/A',
                        checked: true,
                    },
                ]
            } as SearchCheckboxOption,
            {
                icon: IconNamesEnum.At,
                id: 'dip_roll_ups',
                name: 'Roll-up or Refi',
                type: SearchOptionType.checkboxes,
                dependsMust: {
                    "instrument_top_type": ['DIP']
                },
                checkboxes: [
                    {
                        name: 'Roll-up',
                        checked: true,
                    },
                    {
                        name: 'Refi',
                        checked: true,
                    },
                ]
            } as SearchCheckboxOption,

            {
                icon: IconNamesEnum.At,
                id: 'dip_creeping',
                name: 'Creep',
                type: SearchOptionType.checkboxes,
                dependsMust: {
                    "instrument_top_type": ['DIP']
                },
                checkboxes: [
                    {
                        name: 'Yes',
                        checked: true,
                    },
                    {
                        name: 'No',
                        checked: true,
                    },
                ]
            } as SearchCheckboxOption,
        ]
    }),
    hideOptionGroupFromTableSelector({
        groupName: "Plan Treatment",
        showInDownloads: false,
        visible: true,
        colour: '#9BCFB2',
        depends: {
            "situation_type": ['bankruptcy'],
            "plan_treatments": ["trigger"]
        },
        options: [            
            {
                icon: IconNamesEnum.List,
                type: SearchOptionType.checkboxes,
                id: 'impaired_class',
                name: 'Class Treatment',
                checkboxes: [
                    {
                        name: 'Impaired',
                        value: 'Impaired',
                        checked: true,
                    },
                    {
                        name: 'Unimpaired',
                        value: 'Unimpaired',
                        checked: true,
                    },
                    {
                        name: 'Unimpaired / Impaired',
                        value: 'Unimpaired / Impaired',
                        checked: true,
                    },
                ]
            }  as SearchCheckboxOption,
            {
                icon: IconNamesEnum.List,
                type: SearchOptionType.checkboxes,
                id: 'treatment_gift',
                name: 'Recovery Consideration',
                checkboxes: [
                    {
                        name: 'Asset Package',
                        checked: true,
                    },
                    {
                        name: 'Cancelled / No Distribution',
                        checked: true,
                    },
                    {
                        name: 'Cash',
                        checked: true,
                    },
                    {
                        name: 'CVRs',
                        checked: true,
                    },
                    {
                        name: 'Post-petition Instrument',
                        checked: true,
                    },
                    {
                        name: 'Reinstatement',
                        checked: true,
                    },
                    {
                        name: 'Rights Offering Subscription Rights',
                        checked: true,
                    },
                    {
                        name: 'Satisfied / Discharged / Released',
                        checked: true,
                    },
                    {
                        name: 'Trust Interests',
                        checked: true,
                    },
                    {
                        name: 'Other',
                        checked: true,
                    },
                ]
            }  as SearchCheckboxOption,
            {
                icon: IconNamesEnum.Percent,
                id: 'planrecovery',
                type: SearchOptionType.numberrange,
                name: 'Recovery',
                prefix: '',
                suffix: '%',
                minValue: 1,
                maxValue: 100,
                options: {
                    floor: 0,
                    ceil: 100,
                    logScale: false,
                }
            } as SearchNumberRangeOption,
        ]
    })
]
