<loading-status></loading-status>
@if (article$ | async; as situation) {
@if (printMode) {
@for (section of sections; track section) {
<h1 [innerHTML]="getSectioName(section)"></h1>
<ng-container [ngTemplateOutlet]="findTemplate(section)" [ngTemplateOutletContext]="{ situation }"></ng-container>
}
} @else {



@if (situation.body) {
<ng-scrollbar class="ngr-scrollbar" visibility="visible" #body="ngScrollbar">
  <ul class="nav z-1">
    <li class="flex-grow-1">
      <h1>{{situation.title}}</h1>
    </li>
    <li class="">
      <app-header class="__header w-100" [showBack]="true" [article]="situation" [title]="''" [backRoute]="'..'" [contentSet]="'situations'" [followOptions]="followOptions"></app-header>
    </li>
  </ul>
  <div class="row position-sticky top-0 bg-body z-3">
    <div class="col-auto">
      <ul ngbNav #nav="ngbNav" class="nav nav-underline mx-2" [(activeId)]="activeTab" [animation]="true" [keyboard]="'changeWithArrows'" (activeIdChange)="sendAnalyticsForTabChange($event)">
        <li [ngbNavItem]="1">
          <button ngbNavLink>Overview</button>
          <ng-template ngbNavContent>
            <ng-container *ngTemplateOutlet="overview; context: {situation}"></ng-container>
            <div class="row" id="about-the-debtor">
              <div class="col">
                <div class="card border-bd-green-colour mx-1 mb-3">
                  <div class="card-header h4">
                    <a>About The Debtor</a>
                    <a class="btn btn-outline-bankruptcydata btn-sm btn-block float-end mt-1 me-1" target="_blank" href="/organizations/{{situation.body['entity_id']}}">Goto profile</a>
                  </div>
                  <div class="card-body p-0">
                    <app-organization-overview [organization]="situation.body['organization']" [addAppHeader]="false"></app-organization-overview>
                  </div>
                </div>
              </div>
            </div>
          </ng-template>
        </li>
        @if (situation.body['calendar']?.length) {
        <li [ngbNavItem]="10">
          <button ngbNavLink>Dates</button>
          <ng-template ngbNavContent>
            <ng-container *ngTemplateOutlet="dates; context: {situation}"></ng-container>
          </ng-template>
        </li>
        }
        @if (situationHasRoster(situation) || situationHasRepresentatives(situation)) {
        <li [ngbNavItem]="2">
          <button ngbNavLink>Roster</button>
          <ng-template ngbNavContent>
            <ng-container *ngTemplateOutlet="roster; context: {situation}"></ng-container>
          </ng-template>
        </li>
        }
        @if (situationHasRetentions(situation)) {
        <li [ngbNavItem]="3">
          <button ngbNavLink>Retentions</button>
          <ng-template ngbNavContent>
            <ng-container *ngTemplateOutlet="retentions; context: {situation}"></ng-container>
          </ng-template>
        </li>
        }

        @if (situationHasCapitalStructure(situation.body)) {
        <li [ngbNavItem]="4" [destroyOnHide]="true">
          <button ngbNavLink>Capital Structure</button>
          <ng-template ngbNavContent>
            <ng-container *ngTemplateOutlet="capitalStructures; context: {situation}"></ng-container>
          </ng-template>
        </li>
        }
        @if (situationHasPlans(situation)) {
        <li [ngbNavItem]="5" [destroyOnHide]="true">
          @if (situation.body['restructuring_plans'].length == 1) {
          <button ngbNavLink>Restructuring Plan</button>
          }
          @if (situation.body['restructuring_plans'].length > 1) {
          <button ngbNavLink>Restructuring Plans</button>
          }
          <ng-template ngbNavContent>
            <ng-container *ngTemplateOutlet="plans; context: {situation}"></ng-container>
          </ng-template>
        </li>
        }
        @if (situationHasAssetSales(situation)) {
        <li [ngbNavItem]="6" [destroyOnHide]="true">
          <button ngbNavLink>Asset Sales</button>
          <ng-template ngbNavContent>
            <ng-container *ngTemplateOutlet="assetSales; context: {situation}"></ng-container>
          </ng-template>
        </li>
        }

        @if (sitationHasKEIPKERPs(situation)) {
          <li [ngbNavItem]="11">
            <button ngbNavLink>KEIP / KERP</button>
            <ng-template ngbNavContent>
              <ng-container *ngTemplateOutlet="keipkerp; context: {situation}"></ng-container>
            </ng-template>
          </li>
          }

        @if (situationHasTopCreditors(situation)) {
        <li [ngbNavItem]="7">
          <button ngbNavLink>Creditors</button>
          <ng-template ngbNavContent>
            <ng-container *ngTemplateOutlet="creditors; context: {situation}"></ng-container>
          </ng-template>
        </li>
        }
        @if (situationHasHoldings(situation)) {
        <li [ngbNavItem]="8">
          <button ngbNavLink>Holdings</button>
          <ng-template ngbNavContent>
            <ng-container *ngTemplateOutlet="holdings; context: {situation}"></ng-container>
          </ng-template>
        </li>
        }
        @if (situation.body['related_news'].situation.length + situation.body['related_news'].org.length > 0) {
        <li [ngbNavItem]="9">
          <button ngbNavLink>News</button>
          <ng-template ngbNavContent>
            @if (situation.body['related_news'].situation.length) {
            <div class="px-2">
              <h2 [libMasterRouterLink]="['news']" [queryParams]="{entity_name: articleID + ':' + situation.title}">Situation News Stories</h2>
              <ul class="list-group list-group-flush w-100">
                @for (article of situation.body['related_news'].situation; let i = $index; track article) {

                <li class="list-group-item article-display">
                  <div [libMasterRouterLink]="['news' , article.id]" [id]="'article_' + article.id" [queryParams]="{entity_name: articleID + ':' + situation.title}">
                    <app-list-item [article]="article" [isSelected]="false" [dateFormat]="'medium'" [showAttributes]="false"></app-list-item>
                  </div>
                </li>

                }
                <li class="list-group-item article-display mb-4 mt-2">
                  <button class="btn btn-gradient position-absolute start-50 translate-middle top-100 " [libMasterRouterLink]="['news' ]" [queryParams]="{entity_name: articleID + ':' + situation.title}">Show all news for this situation</button>
                </li>
              </ul>
            </div>
            }
            @if (situation.body['related_news'].org.length) {
            <div class="px-2">
              <h2 [libMasterRouterLink]="['news']" [queryParams]="{organization: situation.body['entity_id'] + ':' + situation.body['entity_name']}">Organization News Stories</h2>
              <ul class="list-group list-group-flush w-100">
                @for (article of situation.body['related_news'].org; let i = $index; track article) {

                <li class="list-group-item article-display">
                  <div [libMasterRouterLink]="['news' , article.id]" [id]="'article_' + article.id" [queryParams]="{organization: situation.body['entity_id'] + ':' + situation.body['entity_name']}">
                    <app-list-item [article]="article" [isSelected]="false" [dateFormat]="'medium'" [showAttributes]="false"></app-list-item>
                  </div>
                </li>

                }
                <li class="list-group-item article-display">
                  <button class="btn btn-gradient position-absolute start-50 translate-middle top-100 " [libMasterRouterLink]="['news' ]" [queryParams]="{organization: situation.body['entity_id'] + ':' + situation.body['entity_name']}">Show all news
                    for
                    {{situation.body['entity_name']}}</button>
                </li>
              </ul>
            </div>
            }
          </ng-template>
        </li>
        }

        @if (situation.body['situation_type'] === 'bankruptcy') {
        <li [ngbNavItem]="99" [destroyOnHide]="true">
          <button ngbNavLink>Case Documents</button>
          <ng-template ngbNavContent>
            <div class="container-fluid">
              <documents-viewer [showCaseInfo]="false" [requiredFilters]="{articleID: situation.id, name: situation.title, case_include_type:{value:['non-priority', 'JACs']}, embedded: true}" [documentSearchOptionGroups]="caseDocumentSearchOptions"
                class="d-block" [embedded]="true" [initialSearch]="{ parameters: documentInitialSearch, order: {column: 'docketid', direction: 'desc'}}" contentSet="documents"></documents-viewer>
            </div>
          </ng-template>
        </li>
        }
      </ul>
    </div>
    <div class="col">
      <button class="btn btn-gradient btn-block float-end mt-1 me-1" (click)="startDownload()">Download</button>
    </div>
  </div>

  <div [ngbNavOutlet]="nav"></div>


</ng-scrollbar>
}
}
}

<ng-template #overview let-situation="situation" type="overview">
  @if (situation.body) {
  <div class="container-fluid" id="top">
    @if (sections.includes('overview')) {

    @if (situation.body['summary']; as summary) {
    <div class="row">
      <div class="col-sm-6 my-2">
        <table class="table table-borderless definitions w-100">
          <tbody>
            <tr>
              <td>Situation Type</td>
              <td class="ps-2">{{ situation.description.split(':')[0] }}</td>
            </tr>
            <tr>
              <td>Organization</td>
              <td><a [routerLink]="'/organizations/' + summary.entity_id" class="ps-2">{{summary.entity_name}}</a></td>
            </tr>
            @if (summary.company_type) {
            <tr>
              <td>Company Type</td>
              <td class="ps-2">{{summary.company_type}}</td>
            </tr>
            }
            @if (summary.case_status) {
            <tr>
              <td>
                Status
              </td>
              <td class="ps-2">
                {{ summary.case_status }}
                @if (summary.short_status) {
                &mdash; {{ summary.short_status }}
                }
              </td>
            </tr>
            }
            @if (summary.outcome) {
            <tr>
              <td>
                Outcome
              </td>
              <td class="ps-2">
                {{ summary.outcome }}
                @if (summary.outcome_notes) {
                &mdash; <span [innerHTML]="summary.outcome_notes"></span>
                }
              </td>
            </tr>
            }
            @if (summary.finished_plans?.length) {
            <tr>
              @if (summary.finished_plans.length == 1) {
              <td>Plan</td>
              } @else {
              <td>Plans</td>
              }
              <td class="ps-2">
                <ul class="list-unstyled m-0">
                  @for (plan of summary.finished_plans; track plan.id) {
                  <li>
                    <a class="a_without_href" (click)="gotoPlanByID(plan)">
                      {{plan.name}} ({{plan.status}})
                    </a>
                  </li>
                  }
                </ul>
              </td>
            </tr>
            }

            @if (situation.body['situation_reason']) {
            <tr>
              <td>
                Reason
              </td>
              <td class="ps-2">{{ situation.body['situation_reason'] }}</td>
            </tr>
            }
            @for (timepoint of situation.body['json']?.date_timeline; track timepoint) {

            <tr>
              <td>
                @if (timepoint.type === 'Other') {
                {{ timepoint.event_other }}
                } @else {
                {{ timepoint.type.replace('Bankruptcy Date', 'Petition Date')}}
                }
              </td>
              <td class="ps-2">{{ timepoint.event_date | date}}</td>
            </tr>
            }
            @if (summary.case_number) {
            <tr>
              <td>
                Case number
              </td>
              <td class="ps-2">
                <a (click)="ngbNav!.select(99)">{{ summary.case_number }}</a>
              </td>
            </tr>
            }
            @if (summary.lead_case_number) {
            <tr>
              <td>
                Lead case
              </td>
              <td class="ps-2">
                <a href="/situations/{{summary.lead_case_situation}}">{{ summary.lead_case_name }}: {{ summary.lead_case_number }}</a>
              </td>
            </tr>
            }
            @if (summary.full_case_number) {
            <tr>
              <td>
                Full case number
              </td>
              <td class="ps-2">{{ summary.full_case_number }}</td>
            </tr>
            }
            @if (summary.case_filing_type) {
            <tr>
              <td>
                Filing Type
              </td>
              <td class="ps-2">
                <a [routerLink]="'/situations'" [queryParams]="{situation_type: 'bankruptcy', case_filing_type: situation.body['case_filing_type']}" queryParamsHandling="merge">
                  {{ summary.case_filing_type }}
                </a>
              </td>
            </tr>
            }
            @if (summary.voluntary) {
            <tr>
              <td>
                Voluntary
              </td>
              <td class="ps-2">
                {{ summary.voluntary }}
              </td>
            </tr>
            }
            @if (summary.court_name) {
            <tr>
              <td>
                Filing court &amp; city
              </td>
              <td class="ps-2">
                <a [routerLink]="'/situations'" [queryParams]="{situation_type: 'bankruptcy', court_name: summary['ourCourtID'] + ':' + summary.court_name.replace('Bankruptcy Court', '')}" queryParamsHandling="merge">
                  {{ summary.court_name }} &ndash; {{ summary.filing_city }}
                </a>
              </td>
            </tr>
            }
            @if (situation.body['json'].judges?.length == 1) {
            <tr>
              <td>
                Judge
              </td>
              @if (situation.body['json'].judges[0]; as judge) {
              <td class="ps-2">
                <a target="_blank" href="/people/{{judge.entity_id}}" class="p-0">{{judge.entity_name}}

                </a>
              </td>
              }
            </tr>
            }
            @if (situation.body['json'].judges?.length == 2) {
            <tr>
              <td>
                Judges
              </td>
              <td class="ps-2">
                <table>
                  <tbody>
                    @if (situation.body['json'].judges; as judge) {
                    <tr>
                      <td>
                        <a target="_blank" href="/people/{{judge.entity_id}}" class="p-0">{{judge.entity_name}}</a>
                      </td>
                      <td>{{judge.startDate | date}}</td>
                      <td>{{judge.endDate | date}}</td>
                    </tr>
                    }
                  </tbody>
                </table>
              </td>
            </tr>
            }
            @if (summary.petition_signers) {
            <tr>
              <td>
                Petition Signers
              </td>
              <td class="ps-2">{{ summary.petition_signers }}</td>
            </tr>
            }
            @if (summary.case_industry) {
            <tr>
              <td>
                Industry
              </td>
              <td class="ps-2">
                <a [routerLink]="'/situations'" [queryParams]="{case_industry: summary.case_industry}" queryParamsHandling="merge">
                  {{ summary.case_industry }}
                </a>
              </td>
            </tr>
            }
            @if (summary.sic_category) {
            <tr>
              <td>
                SIC
              </td>
              <td class="ps-2">
                <a [routerLink]="'/situations'" [queryParams]="{sic: summary.sic  + ':' +  summary.sic_category}" queryParamsHandling="merge">
                  {{ summary.sic_category }} ({{ summary.sic }})
                </a>
              </td>
            </tr>
            }
            @if (summary.assets_range?.length > 3) {
            <tr>
              <td>
                Assets
              </td>
              @if (summary.assets_amount && summary.assets_amount.length > 3) {
              <td class="ps-2">${{ summary.assets_amount }}</td>
              } @else {

              <td class="ps-2">{{ summary.assets_range }}</td>

              }
            </tr>
            }
            @if (summary.liabilities_range?.length > 3) {
            <tr>
              <td>
                Liabilities
              </td>
              @if (summary.liabilities_amount && summary.liabilities_amount != '') {
              <td class="ps-2">${{ summary.liabilities_amount }}</td>
              } @else {

              <td class="ps-2">{{ summary.liabilities_range }}</td>

              }
            </tr>
            }
            @if (summary.creditors_range) {
            <tr>
              <td>
                Creditors
              </td>
              <td class="ps-2">{{ summary.creditors_range }}</td>
            </tr>
            }
            @if (summary.employees_range) {
            <tr>
              <td>
                Employees
              </td>
              <td class="ps-2">{{ summary.employees_range }}</td>
            </tr>
            }
            @if (summary.revenue_range) {
            <tr>
              <td>
                Revenues
              </td>
              <td class="ps-2">{{ summary.revenue_range }}</td>
            </tr>
            }
          </tbody>
        </table>
      </div>
      @if (situation.body['jacs'].length || situation.body['calendar'].length) {

      <div class="col-sm-6 my-2">
        @if (situation.body['jacs'].length) {
        <h3 class="column-header">JOINT ADMINISTERED CASES</h3>
        <table class="table table-borderless definitions w-100">
          <tbody>
            @for (jac of situation.body['jacs']; track jac; let i = $index) {
            <tr [class.d-none]="!showAll['jacs'] && i >= 10">
              <td>{{jac.case_filing_date | date}}</td>
              <td>{{jac.case_number}}</td>
              <td><a [routerLink]="'/situations/' + jac.case_id" queryParamsHandling="merge">{{jac.case_name}}</a></td>
            </tr>
            }
            @if (situation.body['jacs'].length > 10) {
            <tr>
              <td colspan="2" class="py-2">
                <button type="button" class="btn btn-outline-dark btn-sm" (click)="showAll['jacs'] = !showAll['jacs']">
                  @if (!showAll['jacs']) {

                  Show All ({{situation.body['jacs'].length}})

                  }
                  @if (showAll['jacs']) {

                  Hide

                  }
                </button>
              </td>
            </tr>
            }
          </tbody>
        </table>
        }
        @if (situation.body['calendar'].length) {
        <h3 class="column-header mb-2">DATES</h3>
        <div class="row">
          @for (entry of getThreeEventsClosestToToday(situation.body['calendar']); track entry.id; let i = $index) {
          <div class="col">
            @if (entry.publicationDate) {
            @if (entry.id > 0) {
            <div class="card text-center h-100 border-bd-green-colour">
              <div class="card-body pb-0">
                <h5 class="card-title fw-bolder">
                  {{ entry.publicationDate | date: 'mediumDate'}}<br>{{ entry.publicationDate | date: 'shortTime'}} {{localTimeZoneShort}}
                </h5>
                <div class="card-text">
                  <a class="show-event-details" [innerHTML]="hearingDescription(entry.extras.hearing_type, entry.extras.hearing_details)" (click)="showEventDetail(entry)"></a>
                </div>
              </div>
              <div class="card-footer bg-transparent border-top-0">
                <ng-container *ngTemplateOutlet="addToCalendar; context: {$implicit:entry}"></ng-container>
              </div>
            </div>
            } @else {
            <div class="card text-center h-100 border-bd-green-colour">
              <div class="card-body pb-0">
                <h5 class="card-title fw-bolder">
                  {{ entry.publicationDate | date }}
                </h5>
                <div class="card-text">
                  <span class="no-spacing" [innerHTML]="entry.extras.hearing_details"></span>
                </div>
              </div>
              <div class="card-footer bg-transparent border-top-0">
                <ng-container *ngTemplateOutlet="addToCalendar; context: {$implicit:entry}"></ng-container>
              </div>
            </div>
            }
            }
          </div>
          }

        </div>
        <div class="row">
          <div class="col">
            <a class="a_without_href" (click)="activeTab = 10">SHOW ALL DATES</a>
          </div>
          <div class="col">
            <div class="text-end text-muted mt-2">All times shown in your local timezone ({{localTimeZone}})</div>
          </div>
        </div>
        }
      </div>

      }
      @if (situation.body['chapter22']; as chapter22) {

      @if (chapter22.length) {
      <div class="col-sm-6 my-2">
        <h3 class="column-header">Chapter 22/33</h3>
        <table class="table table-borderless definitions w-100">
          <tbody>
            @for (chap22 of chapter22; track chap22; let i = $index) {
            <tr>
              <td>{{chap22.case_filing_date | date}}</td>
              <td>{{chap22.case_number}}</td>
              <td><a [routerLink]="'/situations/' + chap22.case_id" queryParamsHandling="merge">{{chap22.case_name}}</a></td>
            </tr>
            }
          </tbody>
        </table>
      </div>
      }

      }
      @if (situation.body['other_situations'].length) {
      <div class="col-sm-6 my-2">
        @if (situation.body['other_situations']; as otherSituations) {

        <h3 class="column-header">OTHER SITUATIONS</h3>
        <table class="table table-borderless definitions w-100">
          <tbody>
            @for (other of otherSituations; track other; let i = $index) {

            <tr>
              <td>{{other.publicationDate}}</td>
              <td><a [routerLink]="'/organizations/' + other.extras.entity_id" queryParamsHandling="merge">{{other.extras.entity_name}}</a></td>
              <td><a [routerLink]="'/situations/' + other.id" queryParamsHandling="merge">{{other.extras.situation_type}}</a></td>
              <td>@if(other.extras.situation_type=="Distressed"){{{other.extras.distressed_situation_reason}}} @if(other.extras.situation_type=="Out Of Court Restructuring"){{{other.extras.ooc_situation_reason}}}</td>
            </tr>

            }
          </tbody>
        </table>

        }
      </div>
      }
    </div>
    }
    <div class="row">
      <div class="col">
        @if (situation.body['distressed_situations']; as distressedSituations) {

        <h3 class="column-header">DISTRESSED TIMELINE</h3>
        <table class="table table-borderless definitions w-100">
          <tbody>
            @for (distress of distressedSituations; track distress; let i = $index) {

            <tr>
              <td>{{distress.situational_date}}</td>
              <td>{{distress.situation_reason}}</td>
              <td>
                <ul class="list-unstyled mb-0">
                  @for (story of distress.news; track story) {
                  <li>
                    <a [routerLink]="'/news/' + story.id" queryParamsHandling="merge">{{story.title}}</a>
                  </li>
                  }
                </ul>
              </td>
            </tr>
            @if (situationHasCapitalStructure(distress)) {
            <tr>
              <td colspan="100">
                <prepost-capital-structure [structure]="distress.capital_structure.prepetitionCapitalStructure" [columns]="distress.capital_structure.prepetitionCapitalStructure_columns" [instrumentIDLookup]="situation.body['instrumentIDLookup']"
                  [classesLookup]="situation.body['classesLookup']"></prepost-capital-structure>
              </td>
            </tr>
            }

            }
          </tbody>
        </table>

        }
      </div>
    </div>
    @if (situation.body['json_overview']; as jsonOverview) {
    <div class="row">
      @if (jsonOverview.status_text?.length > 10) {
      <div class="col-12" [innerHTML]="jsonOverview.status_text"></div>
      }
      @if (jsonOverview.background?.length > 10) {
      <div class="col-12" [innerHTML]="jsonOverview.background"></div>
      }
      @if (jsonOverview.about_the_debtors?.length > 10) {
      <div class="col-12" [innerHTML]="jsonOverview.about_the_debtors"></div>
      }
    </div>
    }

    }
  </div>
  }
</ng-template>

<ng-template #keipkerp let-situation="situation" type="keipkerp">
  @if (situation.body['keip_kerps'].keip.length) {
    <div class="container-fluid">
      <h2>KEIP</h2>
      <table class="table" [@.disabled]="expandAll">
        <thead>
          <tr>
            <th colspan="2">KEIP Name</th>
            <th>KEIP Payment Threshold Amount</th>
            <th>KEIP Payment Maximum Amount</th>
            <th class="text-center">Total Participants</th>
            <th>KEIP Status</th>
          </tr>
        </thead>
        @if (situation.body['keip_kerps'].keip; as akeip) {
        <tbody>
          @for (keip of akeip; track keip) {

          <tr class="clickable" (click)="isExpanded[keip.keip_id] = ! isExpanded[keip.keip_id]" [attr.aria-expanded]="isExpanded[keip.keip_id]">
            <td class="row-toggle"><i-bs [name]="iconNames.ChevronDown" [@openClose]="isExpanded[keip.keip_id] ? 'open' : 'closed'" class="d-inline-block"></i-bs></td>
            <td>{{keip.keip_name}}</td>

            <td>{{keip.keip_payment_minimum_amount}}</td>
            <td>{{keip.keip_payment_maximum_amount}}</td>
            <td class="text-center">{{keip.total_participant_count}}</td>
            <td>{{keip.keip_status}}</td>
          </tr>
          @if (expandAll || isExpanded[keip.keip_id]) {
          <tr @expandCollapse>
            <td colspan="20" class="pt-2 border-left border-right border-bottom rounded-bottom pb-2">
              <div class="collapsible">
              <keip-view [keip]="keip"></keip-view>
              </div>
            </td>
          </tr>
          }

          }
        </tbody>
        }
      </table>
    </div>
    }

    @if (situation.body['keip_kerps'].kerp.length) {
      <div class="container-fluid">
        <h2>KERP</h2>
        <table class="table" [@.disabled]="expandAll">
          <thead>
            <tr>
              <th colspan="2">KERP Name</th>
              <th>Total KERP Payment Amount</th>
              <th class="text-center">Total Participants</th>
              <th>KERP Status</th>
            </tr>
          </thead>
          @if (situation.body['keip_kerps'].kerp; as akerp) {
          <tbody>
            @for (kerp of akerp; track kerp) {

            <tr class="clickable" (click)="isExpanded[kerp.kerp_id] = ! isExpanded[kerp.kerp_id]" [attr.aria-expanded]="isExpanded[kerp.kerp_id]">
              <td class="row-toggle"><i-bs [name]="iconNames.ChevronDown" [@openClose]="isExpanded[kerp.kerp_id] ? 'open' : 'closed'" class="d-inline-block"></i-bs></td>
              <td>{{kerp.kerp_name}}</td>
              <td>{{kerp.kerp_total_payment_amount}}</td>
              <td class="text-center">{{kerp.total_participant_count}}</td>
              <td>{{kerp.kerp_status}}</td>
            </tr>
            @if (expandAll || isExpanded[kerp.kerp_id]) {
            <tr @expandCollapse>
              <td colspan="20" class="pt-2 border-left border-right border-bottom rounded-bottom pb-2">
                <div class="collapsible">
                  <kerp-view [kerp]="kerp"></kerp-view>
                </div>
              </td>
            </tr>
            }

            }
          </tbody>
          }
        </table>
      </div>
      }
</ng-template>

<ng-template #dates let-situation="situation" type="dates">
  @for (entry of situation.body['calendar']; track entry.id; let i = $index) {
  <div class="card border-bd-green-colour m-2" [attr.id]="'event-' + entry.id" [@showEvent]="throbber == entry.id" (@showEvent.done)="throbber = 0">
    <div class="card-body p-1">
      <div class="card-text">
        <div class="row g-0 calendar-date-column-container">
          <div class="col-auto text-center fw-bolder calendar-date-column">
            {{ entry.publicationDate | date: 'mediumDate'}}
            @if (entry.id > 0) {
            <br>{{ entry.publicationDate | date: 'shortTime'}} {{localTimeZoneShort}}
            }
            <ng-container *ngTemplateOutlet="addToCalendar; context: {$implicit:entry}"></ng-container>
          </div>
          <div class="col">
            @if (entry.id > 0) {
            <app-calendar-event-body [entry]="entry" (purchased)="reloadEvent($event, entry.id)"></app-calendar-event-body>
            } @else {
            <span class="no-spacing text-bold" [innerHTML]="entry.extras.hearing_details"></span>
            }
          </div>
        </div>
      </div>
    </div>
  </div>
  }
  <div class="text-muted mt-2">All times shown in your local timezone ({{localTimeZone}})</div>
</ng-template>

<ng-template #roster let-situation="situation" type="roster">
  @if (situationHasRoster(situation)) {
  <app-roster [roster]="situation.body['roster']" [articleID]="situation.id" (activeTab)="gotoHoldings()"></app-roster>
  } @else if (situation.body['json']?.['representives_combined']?.length) {
  <ng-container *ngTemplateOutlet="representives_combined; context: {situation}"></ng-container>
  } @else {
  <ng-container *ngTemplateOutlet="representatives; context: {situation}"></ng-container>
  }
</ng-template>


<ng-template #representatives let-situation="situation" type="representatives">
  @for (representative of situation.body['json']['representatives']; let i = $index; track representative) {
  @switch (representative.role) {
  @case ('Debtor') {
  <div class="ms-4">
    <div class="fw-bold">Debtor represented by</div>
    <ng-container *ngTemplateOutlet="displayRepresentative; context: {representative}"></ng-container>
  </div>
  }
  @case ('U.S. Trustee') {
  <div class="ms-4">
    <div class="fw-bold">US Trustee</div>
    <ng-container *ngTemplateOutlet="displayRepresentative; context: {representative}"></ng-container>
  </div>
  }
  @case ('Trustee') {
  <div class="ms-4">
    <div class="fw-bold">Trustee represented by</div>
    <ng-container *ngTemplateOutlet="displayRepresentative; context: {representative}"></ng-container>
  </div>
  }
  }
  }
</ng-template>
<ng-template #representives_combined let-situation="situation" type="representatives">
  <div class="row">
    <div class="col-xl-6 col-xxl-5">
      <table class="table ms-3">
        <tbody>
          @for (party of situation.body['json']['representives_combined']; let i = $index; track party) {
          <tr>
            <td [attr.colspan]="party.advisor_representatives?.length ? 1 : 2">
              <div class="fw-bold">{{party.role}}</div>
              <ng-container *ngTemplateOutlet="partyTemplate; context: {party}"></ng-container>
            </td>
            @if (party.advisor_representatives?.length) {
            <td>
              @for (rep of party.advisor_representatives; track rep) {
              <div class="ps-2">
                <div class="fw-bold">Represented by</div>
                <div class="fw-bold">{{rep.firm}}</div>
                @if (rep.advisor_representatives_staff.length) {
                @for (person of rep.advisor_representatives_staff; track person) {
                <ng-container *ngTemplateOutlet="partyTemplate; context: {party: person}"></ng-container>
                }
                }
                <ng-container *ngTemplateOutlet="partyTemplate; context: {party: rep}"></ng-container>
              </div>
              }
            </td>
            }
          </tr>
          }
        </tbody>
      </table>
    </div>
  </div>
</ng-template>

<ng-template #partyTemplate let-party="party" type="representatives">
  <ul class="list-unstyled">
    @if (party.advisor_surname) {
    <li>{{party.advisor_name}} {{party.advisor_middle_name}} {{party.advisor_surname}} {{party.advisor_suffix}}</li>
    }
    @if (party.name) {
    <li>{{party.name}}</li>
    }
    @if (party.address1) {
    <li>{{party.address1}}</li>
    }
    @if (party.address2) {
    <li>{{party.address2}}</li>
    }
    @if (party.address3) {
    <li>{{party.address3}}</li>
    }
    @if (party.city) {
    <li>{{party.city}}</li>
    }
    @if (party.state || party.zip) {
    <li>{{party.state}} {{party.zip}}</li>
    }
    @if (party.country) {
    <li>{{party.country}}</li>
    }
    @if (party.phone) {
    <li>Tel: {{party.phone}}</li>
    }
    @if (party.fax) {
    <li>Fax: {{party.fax}}</li>
    }
    @if (party.email) {
    <li>Email: <a href="mailto:{{party.email}}">{{party.email}}</a></li>
    }
  </ul>
</ng-template>

<ng-template #retentions let-situation="situation" type="retentions">
  <div class="container-fluid">
    <div class="row" [@.disabled]="expandAll">
      <div class="col">
        <table class="table table-layout px-3 sticky-header with-expansion">
          <thead>
            <tr>
              <th colspan="2">Retained Date</th>
              <th>Retained Party</th>
              <th>Retained By</th>
              <th>Fee Filing Date</th>
              <th>Order Approved Date</th>
              <th>Total Fees</th>
              <th>Total Expenses</th>
              <th>Total Transaction Fees</th>
            </tr>
          </thead>
          <tbody class="table-group-divider">
            @for (retention of situation.body['retentions']; let i = $index; track retention) {

            <tr class="article-display">
              <td (click)="isExpanded[retention.involvementID] = ! isExpanded[retention.involvementID]" class="expanding">
                <i-bs [name]="iconNames.ChevronDown" [@openClose]="isExpanded[retention.involvementID] ? 'open' : 'closed'" class="d-inline-block"></i-bs>
              </td>
              <td>{{ retention.date_retained | date}}</td>
              <td><a [routerLink]="[ '/organizations', retention.entity_id]" target="_blank">{{ retention.entity_name }}</a></td>
              <td>{{ retention.retained_by_entity_name }}</td>

              <td>{{ retention.date_fees_filed | date}}</td>
              <td>{{ retention.order_approved | date}}</td>
              <td>{{ retention.total_fees }}</td>
              <td>{{ retention.total_expenses }}</td>
              <td>{{ retention.total_transaction_fees }}</td>
            </tr>
            @if (isExpanded[retention.involvementID]) {
            <tr @expandCollapse>

              <td colspan="100">
                <div class="collapsible">
                  <app-retention-article [article]="{id: retention.involvementID, runningOrder: 1, title: '', description: '', publicationDate: '', attributes: [], body: {}, extras: retention}"></app-retention-article>
                </div>
              </td>
            </tr>
            }

            }
          </tbody>
        </table>
      </div>
    </div>
  </div>
</ng-template>

<ng-template #capitalStructures let-situation="situation" type="capitalStructures">
  <div class="container-fluid">

    <div class="row" [@.disabled]="expandAll">
      @if (situation.body['capital_structure']; as capital_structure) {
      <div class="col">
        <capital-structure [capitalstructure]="capital_structure" [instrumentIDLookup]="situation.body['instrumentIDLookup']" [classesLookup]="situation.body['classesLookup']" [expandAll]="expandAll"
          [expandID]="expandIDFromHolding"></capital-structure>
      </div>
      }
    </div>
  </div>
</ng-template>

<ng-template #plans let-situation="situation" type="plans">
  <div class="container-fluid">
    <table class="table pagetable" [@.disabled]="expandAll">
      <thead>
        <tr>
          <th></th>
          <th>Name</th>
          <th>Type</th>
          <th>Filing Type</th>
          <th>Filing Date</th>
          <th>Status</th>
        </tr>
      </thead>
      <tbody>
        @for (plan of situation.body['restructuring_plans']; let i = $index; track plan) {

        <tr [ngClass]="{'clickable collapsed': situation.body['restructuring_plans'].length > 1}">
          @if (situation.body['restructuring_plans'].length > 1) {
          <td class="row-toggle" (click)="isExpanded[plan.plan_name] = ! isExpanded[plan.plan_name]">
            <i-bs [name]="iconNames.ChevronDown" [@openClose]="isExpanded[plan.plan_name] ? 'open' : 'closed'" class="d-inline-block"></i-bs>
          </td>
          }
          @if (situation.body['restructuring_plans'].length == 1) {
          <td></td>
          }
          <td class="plan-name">
            <div><span>{{plan.plan_name}}</span></div>
          </td>
          <td>{{plan.plan_type}}</td>
          <td>{{plan.type_of_filing}}</td>
          <td>{{plan.plan_filing_date | date }}</td>
          <td>{{plan.plan_status}}</td>
        </tr>
        @if (expandAll || isExpanded[plan.plan_name] || situation.body['restructuring_plans'].length == 1) {
        <tr @expandCollapse>
          <td colspan="20" class="pt-2 border-left border-right border-bottom rounded-bottom pb-2">
            <app-plan-view [plan]="plan" [instrumentIDLookup]="situation.body['instrumentIDLookup']" [classesLookup]="situation.body['classesLookup']" [situationID]="situation.id" [expandAll]="expandAll"
              [treatmentColumnVisibility]="situation.body['treatmentColumnVisibility']" class="collapsible" [id]="plan.id"></app-plan-view>
          </td>
        </tr>
        }

        }
      </tbody>
    </table>
  </div>
</ng-template>

<ng-template #assetSales let-situation="situation" type="assetSales">
  @if (situation.body['assetsales'].assetsale.length) {
  <div class="container-fluid">
    <h2>Asset Sales</h2>
    <table class="table" [@.disabled]="expandAll">
      <thead>
        <tr>
          <th colspan="2">Name</th>
          <th>Type</th>
          <th>Going Concern/Liquidation</th>
          <th>Sale Status</th>
          <th class="text-end">Sale Consideration </th>
        </tr>
      </thead>
      @if (situation.body['assetsales'].assetsale; as assetsale) {
      <tbody>
        @for (sale of assetsale; track sale) {

        <tr class="clickable" (click)="isExpanded[sale.assetsale_id] = ! isExpanded[sale.assetsale_id]" [attr.aria-expanded]="isExpanded[sale.assetsale_id]">
          <td class="row-toggle"><i-bs [name]="iconNames.ChevronDown" [@openClose]="isExpanded[sale.assetsale_id] ? 'open' : 'closed'" class="d-inline-block"></i-bs></td>
          <td>{{sale.assetsale_name}}</td>
          <td>{{sale.assetsale_type}}</td>
          <td>{{sale.assetsale_going_concern_liquidation}}</td>
          <td>{{sale.assetsale_status}}</td>
          <td class="text-end">
            @if (sale.total_consideration_amount_derived > 0) {

            {{ sale.total_consideration_amount_derived | currency : sale.total_consideration_amount_derived_currency }}

            } @else {

            N/A

            }
        </tr>
        @if (expandAll || isExpanded[sale.assetsale_id]) {
        <tr @expandCollapse>
          <td colspan="20" class="pt-2 border-left border-right border-bottom rounded-bottom pb-2">
            <div class="collapsible">
              <asset-sale-view [sale]="sale" [biddingprocedures]="situation.body['assetsales'].biddingprocedure" [assets]="situation.body['assetsales'].asset" (instrumentID)="gotoInstrumentByID($event)"></asset-sale-view>
            </div>
          </td>
        </tr>
        }

        }
      </tbody>
      }
    </table>
  </div>
  }

  @if (situation.body['assetsales'].storeclosingsale.length) {

  <h2>Store Closing Sales</h2>
  @if (situation.body['assetsales'].storeclosingsale.length) {
  <table class="table" [@.disabled]="expandAll">
    <thead>
      <tr>
        <th colspan="2">Name</th>
        <th>Sale Status</th>
        <th>Net Store Closing Sale Proceeds</th>
        <th>Total Consultation Fee</th>
      </tr>
    </thead>
    @if (situation.body['assetsales'].storeclosingsale; as storeclosingsale) {
    <tbody>
      @for (sale of storeclosingsale; track sale) {

      <tr class="clickable" (click)="isExpanded[sale.storeclosingsale_id] = ! isExpanded[sale.storeclosingsale_id]" [attr.aria-expanded]="isExpanded[sale.storeclosingsale_id]">
        <td class="row-toggle"><i-bs [name]="iconNames.ChevronDown" [@openClose]="isExpanded[sale.storeclosingsale_id] ? 'open' : 'closed'" class="d-inline-block"></i-bs></td>
        <td>{{sale.storeclosingsale_name}}</td>
        <td>{{sale.net_store_closing_sale_proceeds}}</td>
        <td></td>
      </tr>
      @if (expandAll || isExpanded[sale.storeclosingsale_id]) {
      <tr @expandCollapse>
        <td colspan="20" class="pt-2 border-left border-right border-bottom rounded-bottom pb-2">
          <div class="collapsible">
            <store-closing-sale-view [storeclosingsale]="sale"></store-closing-sale-view>
          </div>
        </td>
      </tr>
      }

      }
    </tbody>
    }
  </table>
  }

  }
</ng-template>

<ng-template #creditors let-situation="situation" type="topCreditors">
  <div class="container-fluid">
    <h2 id="topcreditors">
      Creditors
    </h2>
    @if (situationHasVerifiedTopCreditors(situation)) {
    <table class="table pagetable">
      <thead>
        <tr>
          <th>#</th>
          <th>Name</th>
          <th>Claim Type</th>
          <th>C/U/D</th>
          <th>Amount</th>
          <th>Locations &amp; Contacts</th>
        </tr>
      </thead>
      <tbody>
        @for (creditor of situation.body['json_topcreditors'].topCreditors; let i = $index;; track creditor) {
        <tr>
          <td>#{{i + 1}}</td>
          @if (creditor.entity_type == 'organisation') {
          <td><a [routerLink]="'/organizations/' + creditor.entity_id" queryParamsHandling="merge">{{creditor.entity_name}}</a></td>
          }
          @if (creditor.entity_type == 'person') {
          <td><a [routerLink]="'/people/' + creditor.entity_id" queryParamsHandling="merge">{{creditor.entity_name}}</a></td>
          }
          <td>{{creditor.claim_type}}</td>
          <td>{{creditor.debt_type}}</td>
          <td class="text-end">
            @if (creditor.unsecured_claim_amount_unknown) {

            Unknown

            } @else {

            {{ creditor.amount | currency }}
            @if (creditor.partially_secured) {

            (partially secured)

            }

            }
          </td>
          <td>
            @if (creditor.locations?.length) {
            <ul class="list-unstyled">
              @for (location of creditor.locations; track location) {
              <li>
                <address>
                  {{location.address_display.full_address}}
                </address>
              </li>
              }
            </ul>
            }
            @if (creditor.contacts?.length) {
            <ul class="list-unstyled">
              @for (contact of creditor.contacts; track contact) {
              <li>
                {{contact.person_name}}
                <ul class="list-unstyled">
                  @if (contact.personData?.phone?.length) {
                  <li><a target="_blank" href="tel:{{contact.personData.phone | phone:'US'}}"><i-bs [name]="iconNames.TelephoneOutbound"></i-bs>&nbsp;{{contact.personData.phone}}</a></li>
                  }
                  @if (contact.personData?.email_address?.length) {
                  <li><a target="_blank" href="email:{{contact.personData.email_address}}"><i-bs [name]="iconNames.EnvelopeAt"></i-bs>&nbsp;{{contact.personData.email_address}}</a></li>
                  }
                  @if (contact.personData?.website?.length) {
                  <li><a target="_blank" href="{{contact.personData.website}}"><i-bs [name]="iconNames.BrowserChrome"></i-bs>&nbsp;{{contact.personData.website}}</a>
                  </li>
                  }
                </ul>

              </li>
              }
            </ul>
            }
          </td>
        </tr>
        }
      </tbody>
    </table>
    <court-documents [documents]="situation.body['json_topcreditors'].dockets" colsDefn="col-12"></court-documents>
    } @else {
      <table class="table pagetable">
        <thead>
            <tr>
                <th>Name</th>
                <th>Claim Type</th>
                <th class="text-end">Amount</th>
                <th>Locations &amp; Contacts</th>
                <th>Provenance</th>
            </tr>
        </thead>
        <tbody>
            @for (creditor of situation.body['allcreditors']; track creditor.id) {
                <tr>
                    <td>
                        @if (creditor.entityID > 0) {
                            <followable-link area="organizations" [articleid]="creditor.entityID">{{creditor.creditorName}}</followable-link>
                        } @else {
                            {{creditor.creditorName}}
                        }
                    </td>
                    <td>{{creditor.claimType}}</td>
                    <td class="text-end">{{ (creditor.totalClaimAmount ?? creditor.unsecuredClaimAmount) | currency }}</td>
                    <td>
                        {{ creditor.creditorAddress_address1 }} {{ creditor.creditorAddress_address2 }} {{ creditor.creditorAddress_city }} {{ creditor.creditorAddress_state }} {{ creditor.creditorAddress_zip }} {{ creditor.creditorAddress_country }} <br>
                        {{creditor.contactName}}
                        <ul class="list-unstyled my-0">
                            @if (creditor.contactTelephone?.length) {
                            <li><a target="_blank" href="tel:{{creditor.contactTelephone | phone:'US'}}"><i-bs [name]="iconNames.TelephoneOutbound"></i-bs>&nbsp;{{creditor.contactTelephone}}</a></li>
                            }
                            @if (creditor.contactFax?.length) {
                            <li><a target="_blank" href="fax:{{creditor.contactFax | phone:'US'}}">
                                    Fax&nbsp;{{creditor.fax}}</a></li>
                            }
                            @if (creditor.contactEmail?.length) {
                            <li><a target="_blank" href="email:{{creditor.contactEmail}}"><i-bs [name]="iconNames.EnvelopeAt"></i-bs>&nbsp;{{creditor.contactEmail}}</a></li>
                            }
                        </ul>
                    </td>
                    <td>
                        @switch (creditor.form) {
                            @case ('form206ef') {
                                Form 206/EF
                            }
                            @case ('form204') {
                                Form 204
                            }
                            @case ('form206d') {
                                Form 206D
                            }
                        }
                    </td>
                </tr>
            }
        </tbody>
    </table>

    }
  </div>
</ng-template>

<ng-template #holdings let-situation="situation" type="holdings">
  <div class="container-fluid">
    @for (group of situation.body['roster']['Groups']; let groupIndex = $index; track group) {
    <div>
      @if (group.holdings.length) {

      <h3 class="column-header">
        {{ group.entity_name }}
      </h3>
      @for (holdingInfo of group.holdings; track holdingInfo; let holdingIndex = $index) {
      <div class="row">
        <div class="col my-2">
          <h4 (click)="isExpanded[group.entity_name + holdingIndex] = ! isExpanded[group.entity_name + holdingIndex]">
            <i-bs [name]="iconNames.ChevronDown" [@openClose]="holdingOpenClose(holdingIndex,group.entity_name + holdingIndex) ? 'open' : 'closed'" class="d-inline-block"></i-bs>
            {{ holdingInfo.date | date }}
          </h4>
          @if (holdingOpenClose(holdingIndex,group.entity_name + holdingIndex)) {
          <div @expandCollapse>
            <div class="collapsible">
              <table class="table table-hover definitions w-100">
                <thead>
                  <tr>
                    <th>Organization</th>
                    @for (instrumentPairs of holdingInfo.keys; track instrumentPairs) {
                    <th class="text-end px-1">
                      <ul class="list-inline m-0">
                        @for (pair of instrumentPairs; track pair) {
                        <li class="list-inline-item">
                          <a href="#" (click)="gotoInstrument($event, pair.id)">{{pair.name}}</a>
                        </li>
                        }
                      </ul>
                    </th>
                    }
                  </tr>
                </thead>
                <tbody>
                  @for (row of holdingInfo.table; track row) {
                  <tr>
                    @for (col of row; track col; let i = $index) {

                    @if (i === 0) {
                    <td>
                      <a [routerLink]="'/organizations/' + col.id">{{col.name}}</a>
                    </td>
                    }
                    @if (i > 0) {
                    <td class="text-end px-1">

                      @switch (col.holdingType) {
                      @case ('Currency') {

                      {{ col.value | currency:col.currency }}

                      }
                      @case ('Shares/Units') {
                      {{ col.value | number }}
                      }
                      }
                      @if (col.value.length > 0 && col.position == 'short') {
                      (short)
                      }
                    </td>
                    }

                    }
                  </tr>
                  }
                </tbody>
              </table>
            </div>
          </div>
          }
        </div>
      </div>
      }

      }
    </div>
    }
  </div>
</ng-template>

<ng-template #linkedIn let-linkedin="linkedin">
  <linked-in [handle]="linkedin"></linked-in>
</ng-template>

<ng-template #displayContact let-contact="contact">
  <ul class="list-unstyled">
    <li><a target="_blank" href="{{contact.url}}">{{ contact.entity_name }}</a></li>
    @if (contact.telephone) {
    <li><a target="_blank" href="tel:{{contact.telephone}}"><i-bs [name]="iconNames.TelephoneOutbound"></i-bs>&nbsp;{{contact.telephone | phone:'US'}}</a></li>
    }
    @if (contact.email) {
    <li><a target="_blank" href="email:{{contact.email}}"><i-bs [name]="iconNames.EnvelopeAt"></i-bs>&nbsp;{{contact.email}}</a></li>
    }
    @if (contact.web) {
    <li><a target="_blank" [href]="contact.web"><i-bs [name]="iconNames.BrowserChrome"></i-bs>&nbsp;Website</a></li>
    }
    @if (contact.linkedin?.length > 10) {
    <li>
      <a target="_blank" [href]="contact.linkedin" [ngbPopover]="linkedIn" [popoverContext]="{linkedin : getLinkedInHandle(contact.linkedin)}" popoverClass="linkedin-popover" triggers="mouseenter" [autoClose]="'outside'" [openDelay]="200">
        <i-bs [name]="iconNames.Linkedin"></i-bs>&nbsp;LinkedIn
      </a>
    </li>
    }
  </ul>
</ng-template>


<ng-template #entityWithEmployeesTable let-entities="entities" let-prefix="prefix" let-colspan="colspan">
  <table class="table entity-table w-100">
    <thead>
      <th [attr.colspan]="colspan">Company</th>
      <th>Role</th>
      <th>Start Date</th>
      <th>End Date</th>
    </thead>
    <tbody>
      @for (tr of entities; track tr; let i = $index) {

      @if (showAll[prefix] || i <= 10) { @if (tr.role==='blank' ) { <tr>
        <td colspan="5">
          <p></p>
        </td>
        </tr>

        } @else {

        <tr>
          @if (tr.employees?.length) {
          <td (click)="isExpanded[prefix + i] = ! isExpanded[prefix + i]" class="expanding" style="width: 25px;">
            <i-bs [name]="iconNames.ChevronDown" [@openClose]="isExpanded[prefix + 1] ? 'open' : 'closed'" class="d-inline-block"></i-bs>
          </td>
          }
          @if (tr.employees?.length === 0) {
          <td></td>
          }
          <td>
            <a [routerLink]="tr.url" target="_blank">{{ tr.entity_name }}</a>
          </td>
          @if (tr.role.length) {
          <td> {{tr.role}}</td>
          }
          @if (tr.role.length === 0) {
          <td> {{tr.type}} / {{tr.subtype}}</td>
          }
          <td> {{tr.start_date}}</td>
          <td> {{tr.end_date}}</td>
        </tr>
        @if (tr.employees?.length && isExpanded[prefix + i]) {
        <tr @expandCollapse>
          <td colspan="100">
            <div class="collapsible">
              <ng-container *ngTemplateOutlet="showEmployees; context: {row: tr}"></ng-container>
            </div>
          </td>
        </tr>
        }

        }

        }

        }
        @if (entities.length > 10) {
        <tr>
          <td colspan="10" class="py-2">
            <button type="button" class="btn btn-outline-dark btn-sm p-1" (click)="showAll[prefix] = !showAll[prefix]">
              @if (!showAll[prefix]) {

              Show All ({{entities.length}})

              }
              @if (showAll[prefix]) {

              Hide

              }
            </button>
          </td>
        </tr>
        }
    </tbody>
  </table>
</ng-template>

<ng-template #showEmployees let-row="row">
  <div class="row row-cols-1 row-cols-md-2 row-cols-lg-2 g-2 ms-2  w-100">
    @for (contact of row.employees; track contact) {
    <div class="col">
      <div class="card h-100 mx-1">
        <div class="card-body">
          <p class="card-text">
            <ng-container *ngTemplateOutlet="displayContact; context: {contact}"></ng-container>
          </p>
        </div>
      </div>
    </div>
    }
  </div>
</ng-template>

<ng-template #showAdvisorsWithEmployees let-advisors="value" let-i="i">
  @for (advisor of advisors; track advisor) {

  @if (!advisor.hidden) {
  <p (click)="isExpanded[advisor.entity_name + i ] = ! isExpanded[advisor.entity_name + i ]">
    <i-bs [name]="iconNames.ChevronDown" [@openClose]="isExpanded[advisor.entity_name + i ] ? 'open' : 'closed'" class="d-inline-block"></i-bs>
    {{advisor.entity_name}} ({{advisor.role}} @if (advisor.subtype!='Employee') { — }{{advisor.subtype}})
  </p>
  @if (expandAll || isExpanded[advisor.entity_name + i ]) {
  <div @expandCollapse>
    <div class="collapsible">
      <div class="row row-cols-1 g-2 ms-2 w-100">
        @for (contact of advisor.employees; track contact) {
        <div class="col">
          <div class="card h-100 mx-1">
            <div class="card-body">
              <p class="card-text">
                <ng-container *ngTemplateOutlet="displayContact; context: {contact}"></ng-container>
              </p>
            </div>
          </div>
        </div>
        }
      </div>
    </div>
  </div>
  }
  }
  }
</ng-template>

<ng-template #rawCreditorRow let-creditor="creditor" let-secured="secured">
  <tr>
    <td>{{creditor.creditorName}}</td>
    <td>{{creditor.claimType}}</td>
    <td class="text-end">{{ creditor.amount | currency }}</td>
    <td>
      {{ creditor.building }} {{ creditor.street }} {{ creditor.city }} {{ creditor.state }} {{ creditor.zip }} {{ creditor.country }} <br>
      {{creditor.contact}}
      <ul class="list-unstyled my-0">
        @if (creditor.phone?.length) {
        <li><a target="_blank" href="tel:{{creditor.phone | phone:'US'}}"><i-bs [name]="iconNames.TelephoneOutbound"></i-bs>&nbsp;{{creditor.phone}}</a></li>
        }
        @if (creditor.fax?.length) {
        <li><a target="_blank" href="fax:{{creditor.fax | phone:'US'}}">
            Fax&nbsp;{{creditor.fax}}</a></li>
        }
        @if (creditor.email?.length) {
        <li><a target="_blank" href="email:{{creditor.email}}"><i-bs [name]="iconNames.EnvelopeAt"></i-bs>&nbsp;{{creditor.email}}</a></li>
        }
      </ul>
    </td>
    <td>{{ secured }}</td>
  </tr>
</ng-template>

<ng-template #displayRepresentative let-representative="representative">
  <div>
    <div class="mb-3">
      @if (representative.name) {
      {{ representative.name }}<br>
      }
      @if (representative.firm) {
      {{ representative.firm }}<br>
      }
      @if (representative.address || representative.city || representative.state || representative.zip) {
      @if (representative.address) {
      {{representative.address}},
      }
      @if (representative.city) {
      {{representative.city}},
      }
      @if (representative.state) {
      {{representative.state}},
      }
      @if (representative.zip) {
      {{representative.zip}}
      }
      <br>
      }
      @if (representative.phone) {
      Phone: <a target="_blank" href="tel:{{representative.phone | phone:'US'}}"><i-bs [name]="iconNames.TelephoneOutbound"></i-bs>&nbsp;{{representative.phone | phone:'US'}}</a><br>
      }
      @if (representative.email) {
      Email: <a target="_blank" href="email:{{representative.email}}"><i-bs [name]="iconNames.EnvelopeAt"></i-bs>&nbsp;{{representative.email}}</a>
      }
    </div>
  </div>
</ng-template>

<ng-template #addToCalendar let-entry>
  @if (entry.id > 0) {
  <add-to-calendar-button options="'Apple','Google','iCal','Microsoft365','MicrosoftTeams','Outlook.com'" [attr.name]="entry.extras['case_name']" [attr.location]="entry.extras['court_name']"
    [attr.startDate]="entry.extras['hearing_date_time'] | date:'YYYY-MM-dd'" [attr.endDate]="entry.extras['hearing_date_time'] | date:'YYYY-MM-dd'" [attr.startTime]="entry.extras['hearing_date_time'] | date:'HH:mm'"
    [attr.endTime]="addHours(entry.extras['hearing_date_time'], 1) | date:'HH:mm'" [attr.timeZone]="entry.extras['hearing_timezone']" lightMode="system"
    styleLight="--btn-background: var(--bd-green-colour); --btn-text: #fff; --font: 'Nunito Sans', sans-serif; display: block; margin: 0 auto;" styleDark="--btn-background: #000;  --font: 'Nunito Sans', sans-serif;display: block; margin: 0 auto;"
    buttonStyle="round" size="1|1|1" forceOverlay="true">
  </add-to-calendar-button>
  } @else {
  <add-to-calendar-button options="'Apple','Google','iCal','Microsoft365','MicrosoftTeams','Outlook.com'" [attr.name]="entry.extras.hearing_details" [attr.startDate]="entry.publicationDate | date:'YYYY-MM-dd'"
    [attr.endDate]="entry.publicationDate | date:'YYYY-MM-dd'" lightMode="system" styleLight="--btn-background: var(--bd-green-colour); --btn-text: #fff; --font: 'Nunito Sans', sans-serif; display: block; margin: 0 auto;"
    styleDark="--btn-background: #000;  --font: 'Nunito Sans', sans-serif;display: block; margin: 0 auto;" buttonStyle="round" size="1|1|1" forceOverlay="true">
  </add-to-calendar-button>
  }
</ng-template>

<app-download-dialog [action]="downloadAction"></app-download-dialog>